import React, { useState, useEffect, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { loadCustomerData } from "../../redux/actions/customer/customerAction";
import { useHistory } from "react-router-dom";
import ApiContext from "../../shared/api-interceptor/api-interceptor.component";
import ModalContext from "./modal-context";
import { setIsLoading } from "../../redux/actions/layout/layoutAction";
import { bool, boolean } from "yup";

let logoutTimer;

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (token, userData) => {
  },
  setToken: (token) => {},
  logout: () => {
  },
  role: ""
});


const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem("token");
  const storedUserData = localStorage.getItem("userData");


  return {
    token: storedToken,
    userData: storedUserData
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  const dispatch = useDispatch();
  const history = useHistory();
  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }

/*    useEffect(() => {
      setToken(localStorage.getItem("token"))
      console.log("context",token)

      if(localStorage.getItem("token") ===  null){
        logoutHandler();
      }
    }, [localStorage.length]);*/


  let initRole;

  if (tokenData) {
    initRole = JSON.parse(tokenData.userData)?.role;
  }

  const [token, setToken] = useState(initialToken);
  const [role, setRole] = useState(initRole);
  const apiCtx = useContext(ApiContext);
  const modalCtx = useContext(ModalContext);
  const { apiInterceptor } = apiCtx;
  const userIsLoggedIn = !!token;

  const logoutHandler = async () => {
    await apiInterceptor.delete("/api/User/logout").then(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      localStorage.removeItem("pictureId");
      localStorage.removeItem("trainerId");
      localStorage.removeItem("personalSpaceImage");
      localStorage.clear();
      setToken(null);
      setRole(null);
      console.log("LOGOUT");
      //history?.replace("/");
    }).catch(() => {
      setToken(null);
      setRole(null);
      localStorage.clear();
      dispatch(setIsLoading(false))
    })
  };

  const loginHandler = (token, userData) => {
    setToken(token);
    if (userData) {
      setRole(userData.role);
    }
    localStorage.setItem("token", token);
    if(userData){
      localStorage.setItem("userData", JSON.stringify(userData));

    }

    if (userData && userData.role === "User") {
      dispatch(loadCustomerData(apiInterceptor, userData.userId));
    }

  };

  /*const userData = {
        userId: res.data.userId,
        email: res.data.email,
        role: res.data.roles[0],
        userName: res.data.userName
      };*/
  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    setToken: (token) => setToken(token),
    login: loginHandler,
    logout: logoutHandler,
    role: role
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
