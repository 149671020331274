import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeNavigation, setBusinesCenterPage} from "../../redux/actions/layout/layoutAction";
import DashboardCalendar from "../../components/TrainerDashboard/DashboardCalendar";
import DashboardMarketing from "../../components/TrainerDashboard/DashboardMarketing";
import DashboardReport from "../../components/TrainerDashboard/DashboardReport";
import { getPublicTrainerData } from "../../redux/actions/registration/OnboardingAction";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import ApiContext from "../../shared/api-interceptor/api-interceptor.component";

const TrainerDashboard = () => {
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  const dispatch = useDispatch();
  const userData = useSelector(state => state.onboarding.userData);
  const history = useHistory();
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData)

  useEffect(() => {
    dispatch(changeNavigation(1));
    dispatch(getPublicTrainerData(apiInterceptor,userData.trainerSpace.spaceUrl));
  }, []);
  const { t } = useTranslation();


  return (
    <div className="initialView">
      <div className="content">
        <div className="trainer-dashboard">
          <div className="dashboard-header">
            <div className="left">
              <h2>Hey {userData.firstName},</h2>
              <p>{t("are you ready for a productive day?")}</p>
            </div>
            <div className="right editButton">
              <button onClick={() => {
                  history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}/business-center/calendar`);
                  //dispatch(setBusinesCenterPage(2));
              } }>{t("edit availability")}</button>
            </div>
          </div>
          <DashboardCalendar />
          <DashboardMarketing />
          <DashboardReport />
        </div>
      </div>
    </div>
  );

};

export default TrainerDashboard;
