import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BarChart from "../../common/BarChart";
import { useDispatch, useSelector } from "react-redux";
import { getMonthlyRevenue } from "../../../redux/actions/reports/reportActions";
import { ResponsivePie } from "@nivo/pie";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { ReactComponent as EurSvg } from "../../../images/component_svg/report_eur.svg";
import { ReactComponent as AvaSvg } from "../../../images/component_svg/report_ava.svg";
import { ReactComponent as StarSvg } from "../../../images/component_svg/report_star.svg";
import { ReactComponent as StarNotFilledSvg } from "../../../images/component_svg/report_star_not_filled.svg";
import { ReactComponent as StarFilledSvg } from "../../../images/component_svg/report_start_fill.svg";
import { ReactComponent as ArrowLeftSvg } from "../../../images/component_svg/report_left_arrow.svg";
import { ReactComponent as ArrowRightSvg } from "../../../images/component_svg/report_right_arrow.svg";
import { currencyEnum, currencySymbolEnum, currencySymbolEnumFromIndex } from "../../../enums/enum";
import AuthContext from "../../../utility/context/auth-context";
import { DateTime } from "luxon";

import { CountUp } from "use-count-up";
import DatePicker from "react-datepicker";
import i18n from "i18next";
import de from "date-fns/locale/de";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";

const Report = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  type payoutsType = {
    amount: number,
    arrivalDate: string,
    created: string,
    currency: string,
    payoutId: string,
    status: string
  }

  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  const authCtx = useContext(AuthContext);

  const getMonthlyRevenues = async () => {
    const url = `/api/reports/monthly-revenues`;
    try {

      const response = await apiInterceptor.get(url);

      if (response.status === 200) {
        const helperArray: Array<number> = [];
        for (var propt in response.data) {
          helperArray.push(response.data[propt]);
        }
        setMonthlyRevenues(helperArray);
      }
    } catch (e) {

    }

  };

  const getRevenuesByServiceCategory = async () => {
    const url = `/api/reports/revenues-by-service-category?dateFrom=${dateFrom.toUTC()}&dateTo=${dateTo.toUTC()}`;

    try {

      const response = await apiInterceptor.get(url);

      if (response.status === 200) {
        setRevenuesByServiceCategory(response.data);
      }
    } catch (e) {
    }

  };

  const getRevenuesForYear = async () => {
    const url = `/api/reports/revenues-for-year`;
    try {

      const response = await apiInterceptor.get(url);
      if (response.status === 200) {
        setRevenuesForYear(response.data);
      }
    } catch (e) {

    }

  };

  const getAverageRevenuePerSession = async () => {
    const url = `/api/reports/average-revenue-per-session`;

    try {

      const response = await apiInterceptor.get(url);

      if (response.status === 200) {
        setAverageRevenuePerSession(response.data);
      }

    } catch (e) {

    }

  };

  const getTravelExpensesRevenues = async () => {
    const url = `/api/reports/travel-expenses-revenues?dateFrom=${dateFrom.toUTC()}&dateTo=${dateTo.toUTC()}`;
    try {

      const response = await apiInterceptor.get(url);

      if (response.status === 200) {
        setTravelExpensesRevenues(response.data);
      }
    } catch (e) {

    }

  };

  const getPayouts = async () => {
    const url = `/api/reports/list-payouts?count=14`;

    try {

      const response = await apiInterceptor.get(url);
      if (response.status === 200) {
        setPayouts(response.data);
      }
    } catch (e) {
    }

  };

  const getRatings = async () => {
    const url = `/api/reports/ratings?dateFrom=${dateFrom.toUTC()}&dateTo=${dateTo.toUTC()}`;
    try {

      const response = await apiInterceptor.get(url);
      if (response.status === 200) {
        const { data } = response
        setReviewCount(data.reviewCount);
        setStarCount(data.averageRating);

        let totalStars = 0;

        for (let item of Object.keys(data.ratings)) {
          switch (+item) {
            case 1 :
              setOneStarAmount(data.ratings[item]);
              setOneStarPercent(data.ratings[item] / data.reviewCount * 100);
              totalStars += 1;
              break;
            case 2 :
              setTwoStarAmount(data.ratings[item]);
              setTwoStarPercent(data.ratings[item] / data.reviewCount * 100);
              totalStars += 2;
              break;
            case 3 :
              setThreeStarAmount(data.ratings[item]);
              setThreeStarPercent(data.ratings[item] / data.reviewCount * 100);
              totalStars += 3;
              break;
            case 4 :
              setFourStarAmount(data.ratings[item]);
              setFourStarPercent(data.ratings[item] / data.reviewCount * 100);
              totalStars += 4;
              break;
            case 5 :
              setFiveStarAmount(data.ratings[item]);
              setFiveStarPercent(data.ratings[item] / data.reviewCount * 100);
              totalStars += 5;
              break;
          }
        }

      }
    } catch (e) {
    }

  };


  ChartJS.register(ArcElement, Tooltip, Legend);

  const { t } = useTranslation();
  const labels = [t("jan."), t("feb."), t("mar."), t("apr."), t("may."), t("jun."), t("jul."), t("aug."), t("sep."), t("oct."), t("nov."), t("dec.")];
  const [monthlyRevenues, setMonthlyRevenues] = useState<Array<number>>([]);
  const [revenuesForYear, setRevenuesForYear] = useState<number>(0);
  const [averageRevenuePerSession, setAverageRevenuePerSession] = useState<number>(0);
  const [travelExpensesRevenues, setTravelExpensesRevenues] = useState<number>(0);
  const [payouts, setPayouts] = useState<Array<payoutsType>>([]);
  const [starCount, setStarCount] = useState<number>(0);
  const [fiveStarPercent, setFiveStarPercent] = useState<number>(0);
  const [fiveStarAmount, setFiveStarAmount] = useState<number>(0);
  const [fourStarPercent, setFourStarPercent] = useState<number>(0);
  const [fourStarAmount, setFourStarAmount] = useState<number>(0);
  const [threeStarPercent, setThreeStarPercent] = useState<number>(0);
  const [threeStarAmount, setThreeStarAmount] = useState<number>(0);
  const [twoStarPercent, setTwoStarPercent] = useState<number>(0);
  const [twoStarAmount, setTwoStarAmount] = useState<number>(0);
  const [oneStarPercent, setOneStarPercent] = useState<number>(0);
  const [oneStarAmount, setOneStarAmount] = useState<number>(0);
  const [reviewCount, setReviewCount] = useState<number>(0);
  const [revenuesByServiceCategory, setRevenuesByServiceCategory] = useState<{ privateSessionsRevenue: number, smallGroupsRevenue: number }>({
    privateSessionsRevenue: 0,
    smallGroupsRevenue: 0
  });

  const [dateFrom, setDateFrom] = useState(DateTime.now().minus({ day: 14 }).set({ hour: 0, minute: 0, second: 0 }));
  const [dateTo, setDateTo] = useState(DateTime.now().plus({ day: 2 }).set({ hour: 23, minute: 0, second: 0 }));
  const userData = useSelector(state => state.onboarding.userData);

  useEffect(() => {
    getRevenuesByServiceCategory();
    getTravelExpensesRevenues();
    getRatings();
  }, [dateFrom, dateTo]);
  useEffect(() => {
    getMonthlyRevenues();
    getRevenuesForYear();
    getAverageRevenuePerSession();
    getPayouts();
  }, []);


  const data = [
    {
      "id": "Private",
      "label": "Private",
      "value": revenuesByServiceCategory.privateSessionsRevenue,
      "color": "#FDFC00"
    },
    {
      "id": "Small Group",
      "label": "Small Group",
      "value": revenuesByServiceCategory.smallGroupsRevenue,
      "color": "#FF045D"
    }

  ];


  return (
    <div className="businessCenterReport">
      <div className="businessCenterHeader">
        <div className="header">
          <h1>{t("Report")}</h1>
          <hr />
        </div>
        <div className="date-range-picker">
          <DatePicker
            id={"dateFrom"}

            onChange={(date) => {
              if(date !== null){
                setDateFrom(DateTime.fromJSDate(date))
              }
            }}
            selected={dateFrom.toJSDate()}
            dateFormat="dd.MM.yyyy"
            locale={i18n.language === "de" ? de : ""}
            calendarStartDay={1}
          />
          <span> - </span>
          <DatePicker
            id={"dateTo"}

            onChange={(date) => {
              if(date !== null){
                setDateTo(DateTime.fromJSDate(date))
              }
            }}
            selected={dateTo.toJSDate()}
            dateFormat="dd.MM.yyyy"
            locale={i18n.language === "de" ? de : ""}
            calendarStartDay={1}
          />
        </div>
      </div>
      <div className="charts">
        <div className="row">
          <div className="card">
            <div className="heading">
              <h6>{t("sales development in the year as a whole")}</h6>
            </div>
            <BarChart
              values={monthlyRevenues}
              labels={labels}
            />
          </div>
          <div className="card">
            <div className="pieChart">
              <p>{t("split revenue")}</p>
              <ResponsivePie
                data={data}
                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                innerRadius={0.5}
                padAngle={4}
                cornerRadius={0}
                renderWrapper={true}
                activeOuterRadiusOffset={5}
                borderWidth={0}
                enableArcLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#e2e2e2"
                arcLinkLabelsThickness={5}
                arcLinkLabelsColor={(d) => d.color}
                arcLabelsSkipAngle={10}
                arcLinkLabel={d => `${d.label}  ⌀ ${d.value}  ${currencyEnum[Number(userData.currency)]}`}
                colors={["#FDFC00", "#FF045D"]}
                activeInnerRadiusOffset={0} />
            </div>
          </div>
        </div>
      </div>
      <div className="KPI_container">
        <div className="card">
          <div className="heading">
            <h6>{t("total sales in the current year")}</h6>
          </div>
          <h1 className="kpiNumber">
            <CountUp isCounting end={Number(revenuesForYear) || 0} duration={1.5}
                     formatter={(number) => new Intl.NumberFormat("de-DE").format(number)} /> {currencySymbolEnumFromIndex[userData.currency]}
          </h1>
          <EurSvg />
        </div>
        <div className="card">
          <div className="heading">
            <h6>{t("average revenue per session")}</h6>
          </div>
          <h1 className="kpiNumber">
            <CountUp isCounting end={Number(averageRevenuePerSession) || 0} duration={1.5}
                     formatter={(number) => new Intl.NumberFormat("de-DE").format(number)} /> {currencySymbolEnumFromIndex[userData.currency]}
          </h1>
          <EurSvg />
        </div>
        {/*        <div className="card">
          <div className="heading">
            <h6>{t("total travel expenses revenues")}</h6>
          </div>
          <h1 className="kpiNumber">
            <CountUp isCounting end={travelExpensesRevenues} duration={1.5}
                     formatter={(number) => new Intl.NumberFormat("de-DE").format(number)} /> {currencySymbolEnumFromIndex[userData.currency]}

          </h1>
          <EurSvg />
        </div>*/}

        <div className="card">
          <div className="heading">
            <h6>{t("total ratings")}</h6>

          </div>
          <div className="start_row">
            {starCount >= 1 ? <StarFilledSvg /> : <StarNotFilledSvg />}
            {starCount >= 2 ? <StarFilledSvg /> : <StarNotFilledSvg />}
            {starCount >= 3 ? <StarFilledSvg /> : <StarNotFilledSvg />}
            {starCount >= 4 ? <StarFilledSvg /> : <StarNotFilledSvg />}
            {starCount >= 5 ? <StarFilledSvg /> : <StarNotFilledSvg />}
            <span>{reviewCount}</span>
          </div>
          <div className="amount_column">
            <div className="amount_item">
              <span>5 {t("stars")}</span>
              <div className="bar">
                <div className="range" style={{ width: `${fiveStarPercent}%` }} />
              </div>
              <span>{fiveStarAmount}</span>
            </div>
            <div className="amount_item">
              <span>4 {t("stars")}</span>
              <div className="bar">
                <div className="range" style={{ width: `${fourStarPercent}%` }} />
              </div>
              <span>{fourStarAmount}</span>
            </div>
            <div className="amount_item">
              <span>3 {t("stars")}</span>
              <div className="bar">
                <div className="range" style={{ width: `${threeStarPercent}%` }} />
              </div>
              <span>{threeStarAmount}</span>
            </div>
            <div className="amount_item">
              <span>2 {t("stars")}</span>
              <div className="bar">
                <div className="range" style={{ width: `${twoStarPercent}%` }} />
              </div>
              <span>{twoStarAmount}</span>
            </div>
            <div className="amount_item">
              <span>1 {t("stars")}</span>
              <div className="bar">
                <div className="range" style={{ width: `${oneStarPercent}%` }} />
              </div>
              <span>{oneStarAmount}</span>
            </div>
          </div>

          <StarSvg />
        </div>


        <div className="card">
          <div className="payouts">
            <div className="heading">
              <h6 className={"titel"}>{t("your payouts")}</h6>
              <span className={"titel_desc"}>{t("Due to an audit by Stripe, your first withdrawal may take 7-14 business days.")}</span>
              {/*          <ArrowLeftSvg/>
              <ArrowRightSvg />*/}
            </div>

            <div className="tableHeader">
              <div className="muted">{t("day / date")}</div>
              <div className="muted">{t("amount")}</div>
            </div>
            {payouts.map((item: payoutsType) =>
              <div className="payouts_row">
                <p>
                  {DateTime.fromISO(item.created, {locale:i18n.language}).weekdayLong}, {DateTime.fromISO(item.created).toFormat("dd.MM.yyyy")}
                </p>
                <p><CountUp isCounting end={item.amount} duration={1.5}
                            formatter={(number) => new Intl.NumberFormat("de-DE").format(number)} /> {currencySymbolEnumFromIndex[userData.currency]}
                </p>
              </div>
            )}
          </div>

          <EurSvg />
        </div>
      </div>
    </div>
  );
};
export default Report;
