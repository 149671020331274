import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TrainerReviews from "./TrainerReviews";
import { Redirect, useParams } from "react-router-dom";

import {
    getPublicTrainerData
} from "../../../redux/actions/registration/OnboardingAction";
import { changeNavigation } from "../../../redux/actions/layout/layoutAction";

import imagePlaceholder from "../../../images";
import { useHistory } from "react-router-dom";
import { ReactComponent as ArrowDown } from "../../../images/component_svg/arrowDownSVG.svg";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../utility/context/auth-context";
import ModalContext from "../../../utility/context/modal-context";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";

export interface Review {
    pageIndex: number,
    totalRecords: number,
    hasPreviousPage: boolean,
    hasNextPage: boolean,
    items: Array<reviewItem>
}

export interface reviewItem {
    id: number,
    customerFirstName: string,
    customerLastName: string,
    customerProfilePictureUri: string,
    rating: number,
    headline: string,
    message: string
}


const PersonalSite = () => {
    const history = useHistory();
    const apiContext = useContext(ApiContext);
    const { apiInterceptor } = apiContext;
    const dispatch = useDispatch();
    const [personalSpaceImage, setPersonalSpaceImage] = useState<string>(imagePlaceholder.personalSpace);
    const publicTrainerData = useSelector((state) => state.onboarding.publicTrainerData);
    const trainerID = useSelector((state) => state.onboarding.trainerId);
    const [showSpinner, setShowSpinner] = useState(true);
    const modalCtx = useContext<any>(ModalContext);

    const { i18n, t } = useTranslation();

    type ParamsType = {
        id: string
    }


    const { id } = useParams<ParamsType>();

    useEffect(() => {
        dispatch(getPublicTrainerData(apiInterceptor, id));
        setShowSpinner(false);
        dispatch(changeNavigation(1));
    }, []);

    const authCtx = useContext(AuthContext);
    const [reviews, setReviews] = useState<Review>({
        pageIndex: -1,
        totalRecords: -1,
        hasPreviousPage: false,
        hasNextPage: false,
        items: []
    });

    useEffect(() => {
        getReviews();

    }, [publicTrainerData]);

    useEffect(() => {
        if (history.location.pathname.includes("redirect-dashboard") || history.location.pathname.includes("business-center-invoices") || history.location.pathname.includes("business-center-calendar")) {
            modalCtx.setModalType(1);
            modalCtx.setIsActive(true);
        }
    }, []);

    const getReviews = async () => {
        if (!!publicTrainerData.trainerCode) {
            const url = `/api/trainers/reviews/${publicTrainerData.trainerCode}`;
            try {

                const response = await apiInterceptor.get(url);

                if (response.status === 200) {
                    const res = await response.data;
                    setReviews(res);
                    console.log(res);
                }
            } catch (e) {
            }
        }
    };

    return (
        !showSpinner && <Fragment>
            {!trainerID ?
                (
                    <Redirect to="/trainerNotFound" />
                )
                : (
                    <div className="trainer-personal-site">
                        <div className="image-container">
                            <img
                                src={publicTrainerData.trainerSpace?.logoUrl != "" && publicTrainerData.trainerSpace?.logoUrl != null ? `${process.env.REACT_APP_BE_BASEURL}/api/files/${publicTrainerData.trainerSpace.logoUrl}/download` : personalSpaceImage}
                                className="img-fluid" alt="personalProfile" />
                            <div className="bottom-label ">
                                <div className="trainer-personal-site-text-container">
                                    <h3 className="text-yellow">{publicTrainerData.trainerSpace?.name != null ? publicTrainerData.trainerSpace?.name : `${publicTrainerData.firstName} ${publicTrainerData.lastName}`}</h3>
                                    <h6 className="text-white">{publicTrainerData.trainerSpace?.slogan != null ? publicTrainerData.trainerSpace?.slogan : t("trainerSince")}</h6>
                                    {publicTrainerData.partnerPlatform !== null && !!publicTrainerData.personalFitness?.badgeUrl ? (
                                        <img className={"pf-badge"} src={publicTrainerData.personalFitness?.badgeUrl} />
                                    ) : null}
                                </div>

                                <div className="col-sm-2">
                                    <span className="btn">{/*place bubble/chat icon here */}</span>
                                </div>
                            </div>
                        </div>
                        {reviews.items.length <= 0 ? null : <ArrowDown />}

                        {reviews.items.length <= 0 ? null : <TrainerReviews reviews={reviews} />}
                    </div>
                )
            }
        </Fragment>
    );
};

export default PersonalSite;
