import { ReactComponent as VirtualSvg } from "../../../images/component_svg/virtual.svg";
import { ReactComponent as OnSiteSvg } from "../../../images/component_svg/locationSVG.svg";
import { ReactComponent as EditSvg } from "../../../images/component_svg/edit.svg";
import { ReactComponent as SubSVG } from "../../../images/component_svg/sub.svg";
import { ReactComponent as EditSVG } from "../../../images/component_svg/edit.svg";
import {
  ReactComponent as PackageSVG
} from "../../../images/component_svg/businessCenterSvgs/packagesSubscriptionsIcon.svg";

import React, { useContext, useState } from "react";
import { packageObject } from "../../../redux/reducers/packages/packagesReducer";
import AuthContext from "../../../utility/context/auth-context";
import ModalContext from "../../../utility/context/modal-context";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTrainerPackages } from "../../../redux/actions/packages/packagesAction";
import { currencySymbolEnumFromIndex } from "../../../enums/enum";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";



const BusinessCenterPackageServiceListItem = ({ forEnabled, item }: { forEnabled: boolean, item: packageObject }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext<any>(ModalContext);
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  const disablePackage = async () => {
    const url = `/api/packages/${item.id}/disable`;

    try {

      const response = await apiInterceptor.put(url);
      setPopup(false);

      if (response.status === 200 ) {

        dispatch(getTrainerPackages(apiInterceptor,true));
        modalCtx.setModalType(0);
        modalCtx.setMessage(response.data.message);
        modalCtx.setIsActive(true);

      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(response.data.message);
        modalCtx.setIsActive(true);
      }
    } catch (e) {
    }

  };

  return (
    <div className="serviceRow">
      <p>{item.name}</p>
      <p>{item.expirationDateTime}</p>
      <div className="virtual_price">
        <PackageSVG />
        {item.price.toString().split(".")[0]}<sup>{item.price.toString().split(".")[1]}</sup>{currencySymbolEnumFromIndex[publicTrainerData.currency]}
      </div>
      {/*      <div className="onSite_price">
        <SubSVG/>
        {"169.99".split(".")[0]}<sup>{"169.99".split('.')[1]}</sup>{currencySymbolEnumFromIndex[publicTrainerData.currency]}
      </div>*/}
      <div className="edit" onClick={() => forEnabled && setPopup(!popup)}
           style={forEnabled ? {} : { display: "none" }}>
        <EditSvg />
      </div>
      <div className="EditPopup" style={popup ? { display: "flex" } : {}}>

        {forEnabled && <p onClick={disablePackage}>{t("disable")}</p>}
      </div>
    </div>
  );
};

export default BusinessCenterPackageServiceListItem;
