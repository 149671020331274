import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeNavigation } from "../../redux/actions/layout/layoutAction";
import { ReactComponent as StarNotFilledSvg } from "../../images/component_svg/report_star_not_filled.svg";
import { ReactComponent as StarFilledSvg } from "../../images/component_svg/report_start_fill.svg";
import * as yup from "yup";
import { Link, useHistory } from "react-router-dom";
import ModalContext from "../../utility/context/modal-context";
import ApiContext from "../../shared/api-interceptor/api-interceptor.component";


const ReviewSection = () => {

  const customerData = useSelector(state => state.customer);
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);
  const localData: string | any = localStorage.getItem("userData");
  const profilePicturePlaceholder =
    "https://intern-creativemindz.de/wp-content/uploads/user3.svg";
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  const dispatch = useDispatch();
  const history = useHistory();
  const modalCtx = useContext<any>(ModalContext)
  const [starCount, setStarCount] = useState<number>(2);
  const [headline,setHeadline] = useState<string>('');
  const [message,setMessage] = useState<string>('')
  const { i18n , t} = useTranslation();


  const SaveReview = async () => {
    const url = `/api/customers/review`;

    const data =
    {
      rating: Number(starCount),
      headline: headline,
      message: message
    }


    const response = await apiInterceptor.post(url, data);

    if (response.status === 200) {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("thank you, your evaluation process was successful"))
      modalCtx.setIsActive(true)
    }else{
      modalCtx.setModalType(0);
      modalCtx.setMessage(response.data.message)
      modalCtx.setIsActive(true)
    }
  }

  useEffect(() => {
    dispatch(changeNavigation(1));
  }, []);

  return (

    <div className="reviewSection">
      <div className="initialView">
        <div className="content">
          <div className="card">
            <div className="header">
              <h3>{t("review section")}</h3>
            </div>
            <div className="profile-row">
              <div className="avatar">
                <img
                  src={customerData.profilePictureUri !== "" ? `${process.env.REACT_APP_BE_BASEURL}/api/files/${customerData.profilePictureUri}/download` : profilePicturePlaceholder }
                />
              </div>
              <div className="name">
                {customerData.firstName} {customerData.lastName}
                <p>{t("by giving a review, users will see your profile picture and your nickname.")}</p>
              </div>
            </div>
            <div className="rating-form">
              <p>Please rate your trainer from one to five stars. </p>
              <div className="start_row">
                <div className="star" onClick={() => setStarCount(1)}>
                  {starCount >= 1 ? <StarFilledSvg /> : <StarNotFilledSvg />}
                </div>
                <div className="star" onClick={() => setStarCount(2)}>
                  {starCount >= 2 ? <StarFilledSvg /> : <StarNotFilledSvg />}
                </div>
                <div className="star" onClick={() => setStarCount(3)}>
                  {starCount >= 3 ? <StarFilledSvg /> : <StarNotFilledSvg />}
                </div>
                <div className="star" onClick={() => setStarCount(4)}>
                  {starCount >= 4 ? <StarFilledSvg /> : <StarNotFilledSvg />}
                </div>
                <div className="star" onClick={() => setStarCount(5)}>
                  {starCount >= 5 ? <StarFilledSvg /> : <StarNotFilledSvg />}
                </div>
              </div>
              <div className="fields">
                <input
                  type={"text"}
                  placeholder={t("type your headline")}
                  value={headline}
                  onChange={(e) => setHeadline(e.target.value)}
                />

                <textarea
                  placeholder={t("please give us an honest review")}
                  rows={10}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              <div className="buttons">
                <button className={"outlineButton"}
                onClick={() => {
                  history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}/customer/customer-dashboard`);

                }}
                >{t("back")}</button>
                <button
                  onClick={SaveReview}
                >{t("save")}</button>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  );

};

export default ReviewSection;
