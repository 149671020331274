import React, { useContext, useEffect, useState } from "react";
import {
     getPublicTrainerData,
    setOnboardingStatusAction
} from "../../../redux/actions/registration/OnboardingAction";
import {changeNavigation } from '../../../redux/actions/layout/layoutAction'

import { useDispatch, useSelector } from "react-redux";
import {useHistory} from "react-router-dom";
import PackageBookingContent from "./PackageBookingContent";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";

const PackageBookingPage = () => {
    const apiContext = useContext(ApiContext);
    const {apiInterceptor} = apiContext;
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedPackageForBooking = useSelector(state => state.package.selectedPackageForBooking)
    const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData)

    useEffect(() => {
        if(selectedPackageForBooking.id === -1){
            history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}/packages`)
        }

    }, [selectedPackageForBooking]);
    useEffect(() => {
        //dispatch(setTrainerData())
        dispatch(getPublicTrainerData(apiInterceptor,history.location.pathname.split('/')[2]))

        dispatch(changeNavigation(1));
    }, []);

    return (
        <div className={'trainerBookingPageTwo'}>
            <div className="initialView">
                <div className="content">
                    <div className="row">
                        <PackageBookingContent/>
                       {/* <PackageBookingSummary/>*/}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default PackageBookingPage;
