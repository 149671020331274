import React, { useEffect, useState, Fragment, useContext } from "react";
import { ReactComponent as Path } from "../../../images/component_svg/path.svg";
import { ReactComponent as SearchPlusSvg } from "../../../images/component_svg/search.svg";
import SubscriptionModal from "../SubscriptionModal";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCustomerData,
  loadCustomerPackages,
  setSelectedCreditItemForBooking
} from "../../../redux/actions/customer/customerAction";
import CurrentPackage from "../CurrentPackage";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { setSelectingFromCancellation } from "../../../redux/actions/booking/bookingAction";
import modalContext from "../../../utility/context/modal-context";
import ModalContext from "../../../utility/context/modal-context";
import { Alert } from "reactstrap";

import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";

export default function CustomerDetails({ randomHour,cancelledBookings }: any) {
  const history = useHistory();
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const customerData = useSelector(state => state.customer);
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);

  const [startHours, setStartHours] = useState(0);
  const [startMinutes, setStartMinutes] = useState(0);
  const [endHours, setEndHours] = useState(0);
  const [endMinutes, setEndMinutes] = useState(0);
  const dispatch = useDispatch();
  const maxHours = 1000;
  const { nextBookingStartTime, lastBookingEndTime } = customerData.upcomingSessions;
  const { t, i18n } = useTranslation();
  const modalCtx = useContext<any>(ModalContext);
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;

  useEffect(() => {
    const IntervalId = setInterval(() => {
      const diffStart = (DateTime.fromISO(nextBookingStartTime).diff(DateTime.now(), ["hours", "minutes", "seconds"])).toObject();
      const diffEnd = (DateTime.now().diff(DateTime.fromISO(lastBookingEndTime), ["hours", "minutes", "seconds"])).toObject();

      setStartHours(diffStart.hours || 0);
      setStartMinutes(diffStart.minutes || 0);

      setEndHours(diffEnd.hours || 0);
      setEndMinutes(diffEnd.minutes || 0);

    }, 1000);

    return () => {
      clearInterval(IntervalId);
    };
  });

  const getRemainingSeasons = () => {
    let count: number = 0;
    customerData.packages?.forEach((item: any) => {
      item.packageServices?.forEach((itm: any) => {
        if (itm.remainingCount === null) {
          count += itm.totalCount;
        } else {
          count += itm.remainingCount;
        }
      });
    });

    /*    cancelledBookings.forEach((item:any) => {
          if(item?.refundStatus !== 3){
            count += 1
          }
        })*/


    count += Array.isArray(cancelledBookings) ? cancelledBookings.length : 0;

    return count;
  };


  const requestRefund = async (id: number) => {

    const url = `/api/Payments/${id}/customer-refund`;
    try {

      const response = await apiInterceptor.put(url, {});

      if (response.status == 200) {
        modalCtx.setModalType(0);
        modalCtx.setMessage(response.data.message);

      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(response.data.message);
        modalCtx.setIsActive(true);

      }
    } catch (error) {

    }

  };

  const openRefundModal = (id: number) => {


    modalCtx.setModalType(2);
    modalCtx.setMessage(t("do you really want to request the payout"));
    modalCtx.setConfirmationCallback({ cb: () => requestRefund(id) });
    modalCtx.setIsActive(true);
  };

  const formatNumber = (time: number) => time.toString().length === 1 ? "0" + time : time;

  useEffect(() => {
    dispatch(loadCustomerPackages(apiInterceptor,customerData.id));
  }, [customerData.id, dispatch]);
  const localData: string | any = localStorage.getItem("userData");


  const handleBooking = (booking: any) => {
    //Book from CancellationCredit
    dispatch(setSelectingFromCancellation(true));
    dispatch(setSelectedCreditItemForBooking(booking));

    history.push(`/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-booking`);
  };

  return (
    <div className="pro-detail">
      <div className="card">
        <div className="card-content cust-cont">
          <div className="left">
            <div className="cout-name">
              <h3>
                {customerData.firstName} {customerData.lastName}
              </h3>
              <a href={`mailto:${JSON.parse(localData)?.email}`}>{JSON.parse(localData)?.email}</a>
            </div>
            <div className="nt-ses ses-time">
              <p>{t('timeNextSession')}</p>
              <div className="time">
                {startHours > maxHours ? (
                  <Fragment>
                    <div className="time-detail">
                      <Path />
                    </div>
                  </Fragment>
                ) : startHours === 0 && startMinutes === 0 ? (
                  <button
                    onClick={() => history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-booking`)}
                  >
                    {t('bookNextSession')}
                  </button>
                ) : (
                  <Fragment>
                    <h2 className="time-detail">
                      {formatNumber(startHours)}
                      <span>{t('hours')}</span>
                    </h2>
                    <div>:</div>
                    <h2 className="time-detail">
                      {formatNumber(startMinutes)}
                      <span>{t('minutes')}</span>
                    </h2>
                  </Fragment>
                )}
              </div>
            </div>
            <div className="lt-ses ses-time">
              <p>{t('timeLastSession')}</p>
              {endHours === 0 && endMinutes === 0 ? (
                <Fragment>
                  <div className="time-detail">
                    <Path />
                  </div>
                </Fragment>
              ) : (
                <div className="time">
                  <h2 className="time-detail">
                    {formatNumber(endHours)}
                    <span>{t('hours')}</span>
                  </h2>
                  <div>:</div>
                  <h2 className="time-detail">
                    {formatNumber(endMinutes)}
                    <span>{t('minutes')}</span>
                  </h2>
                </div>
              )}
            </div>

            {!customerData.reviewExist ? (
              <button
                onClick={() => {
                  history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}/customer/rate-your-trainer`);
                }}
              >
                {t('rate your trainer')}
              </button>
            ) : null}
          </div>
          <div className="right">
            <div className="you-wal">
              <div className="cancel-ses">
                <div className="wall">
                  <h3>{t('Your wallet')}</h3>
                  <p>{t('credits for cancelled sessions')}</p>
                </div>
                <div className="rem-ses">
                  <p>{t('remaining sessions')}</p>
                  <div className="sec-cont">{getRemainingSeasons()}</div>
                </div>
              </div>
              <div className="cancel-table">
                {cancelledBookings.map((booking: any, index: number) => {
                  /*if(booking.refundStatus !== 2){*/
                  if (!!booking.service) {
                    return (
                      <div className="tr-tabl" key={index}>
                        <div className="td-tabl">
                          {booking.service.name} - {booking.service.description}
                        </div>
                        <div className="td-tabl">
                          {t('cancelled date')}: {DateTime.fromISO(booking.startTime).toFormat('dd.MM.yyyy')}
                        </div>
                        {booking.isRefundable && (
                          <div className="td-tabl">{<SearchPlusSvg onClick={() => openRefundModal(booking.id)} />}</div>
                        )}
                        <div className="td-tabl">
                          <p className={'book'} onClick={() => handleBooking(booking)}>
                            {' '}
                            {t('book')}
                          </p>
                        </div>
                      </div>
                    );
                  }

                  return null;
                })}
              </div>
              <div className="curr-pack">
                <p>{t('current packages')}</p>
                <div className="wall-table">
                  {customerData.packages?.map((item: any) => {
                    return <CurrentPackage item={item} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
