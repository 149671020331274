import { Dispatch } from "react";
import { DateTime } from "luxon";
import i18n from "i18next";
import { serializeQueryParams } from '../../../utility/url';
import { AxiosInstance } from "axios";

export const getAvailableTimesDuration = (
    apiInterceptor:AxiosInstance,
  trainerUrl: string,
  date: string = "-1",
  service: number = -1,
  duration: number = -1,
  outwardJourneyDuration: number = -1,
  returnJourneyDuration: number = -1
) =>
  async (dispatch: Dispatch<any>) => {

    const regEx = new RegExp(":", "g");
    const refactorDate = date.replace(regEx, "%3A");

    const url = `/api/bookings/available-times-duration/${trainerUrl}/public?${
      date != "-1" ? `startDate=${refactorDate}` : ""
    }${duration != -1 ? `&duration=${duration}` : ""}${
      service != -1 ? `&service=${service}` : ""
    }${
      outwardJourneyDuration != -1 ? `&outwardJourneyDuration=${outwardJourneyDuration}` : ""
    }${
      returnJourneyDuration != -1 ? `&returnJourneyDuration=${returnJourneyDuration}` : ""
    }`;

    if (trainerUrl !== null && trainerUrl !== "") {

      const response = await apiInterceptor(url, {
        method: "GET",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          accept: "text/plain"
          //'Authorization': 'Bearer ' + localStorage.getItem('token')
        }

      });

      const data = await response.data;
      dispatch({
        type: "availableTimesDuration",
        payload: [...data]
      });
    }


  };
export const setSelectedTime = (payload: { startDate: string, endDate: string, totalPrice?: number, serviceFee?: number, servicePrice?: number, travelExpense?: number }) => {
  return {
    type: "setSelectedTime",
    payload: payload
  };
};

export const addSelectedTime = (item: { startDate: string, endDate: string, totalPrice?: number, serviceFee?: number, servicePrice?: number, travelExpense?: number, packageId?: number, id?: number }) => {

  return {
    type: "addSelectedTime",
    payload: {
      startDate: item.startDate,
      endDate: item.endDate

    }
  };
};

export const updateSelectedTime = (payload: { startDate: string, totalPrice: number, serviceFee: number, servicePrice: number, travelExpense: number, packageId: number }) => {

  return {
    type: "updateSelectedTime",
    payload: payload
  };
};

export const dropSelectedTime = (payload: string) => {
  return {
    type: "dropSelectedTime",
    payload: payload
  };
};
export const eraseSelectedTime = () => {
  return {
    type: "eraseSelectedTime",
    payload: []
  };
};


export const setTotalPrice = (payload: number) => {

  return {
    type: "setTotalPrice",
    payload: payload
  };

};

export const setServiceFee = (payload: number) => {

};

export const setTravelExpenses = (payload: number) => {

};

export const getBookingPolicy = (apiInterceptor:AxiosInstance) => async (dispatch: Dispatch<any>) => {
  if (localStorage.getItem("token")) {
    try {
      const url = `/api/bookings/allowedtime`;
      const response = await apiInterceptor.get(url);

      if (response.status === 200) {
        dispatch({
          type: "getBookingPolicy",
          payload: response.data
        });
      }

    } catch (err) {
    }
  }
};
export const getBookingPolicyPublic = (apiInterceptor:AxiosInstance,spaceUrl: string) => async (dispatch: Dispatch<any>) => {
  try {

    const url = `/api/bookings/allowedtime/${spaceUrl}/public`;

    const response = await apiInterceptor.get(url);

    if (response.status === 200) {
      dispatch({
        type: "getBookingPolicy",
        payload: response.data
      });
    }

  } catch (err) {
  }
};
export const getTravelData = (apiInterceptor:AxiosInstance,trainerId: number, street: string, housenumber: string, city: string, postalcode: string, country: string) => async (dispatch: Dispatch<any>) => {
  try {

    const url = `/api/bookings/travel-data?trainerId=${trainerId}&Street=${street}&Housenumber=${housenumber}&City=${city}&PostalCode=${postalcode}&Country=${country}`;

    const response = await apiInterceptor.get(url);

    if (response.status === 200) {
      dispatch({
        type: "getTravelData",
        payload: response.data
      });
    } else {
      dispatch({
        type: "getTravelData",
        payload: {
          outwardJourneyDuration: -1,
          returnJourneyDuration: -1
        }
      });
    }

  } catch (err) {
  }
};

export const setTravelData = (payload: { outwardJourneyDuration: number, returnJourneyDuration: number }) => {
  return {
    type: "getTravelData",
    payload: payload
  };
};

export const getInvoices = (apiInterceptor:AxiosInstance,dateFrom: DateTime, dateTo: DateTime) => async (dispatch: Dispatch<any>) => {
  try {

    const url = `/api/trainers/invoices?dateFrom=${dateFrom.toUTC().toISO()}&dateTo=${dateTo.toUTC().toISO()}`;

    const response = await apiInterceptor.get(url);

    if (response.status === 200) {
      dispatch({
        type: "getInvoices",
        payload: response.data
      });
    }
  } catch (err) {
  }
};

export const getBookingSmallGroupCalendarItems = (apiInterceptor:AxiosInstance,spaceName: string, serviceId = -1, includeDisabled = false) => async (dispatch: Dispatch<any>) => {
  try {
    let params = {
      serviceId: serviceId === -1 ? undefined : serviceId,
      includeDisabled: includeDisabled
    }
    const url = `/api/bookings/available-times-small-group/${spaceName}/public?${serializeQueryParams(params)}`;

    if (spaceName !== null) {
      const response = await apiInterceptor.get(url);


      if (response.status === 200) {
        dispatch({
          type: "availableSmallGroupSessions",
          payload: response.data
        });
      }
    }
  } catch (err) {
  }
};

export const setSelectingFromPackage = (payload: boolean) => {
  return {
    type: "selectingFromPackage",
    payload: payload
  };
};

export const setSelectingFromCancellation = (payload: any) => {
  return {
    type: "selectingFromCancellation",
    payload: payload
  };
};


