import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SignUpFormsAction } from "../../../redux/actions/registration/SignUpFormsAction";
import ModalContext from "../../../utility/context/modal-context";
import { emailRegex } from "../../../enums/enum";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";

const TrainerLoginOrSignUpForm = () => {
  const [email, setEmail] = useState<string>("");
  const [emailErr, setEmailErr] = useState<string>("");
  const modalCtx = useContext<any>(ModalContext);
  const dispatch = useDispatch();
  const { i18n , t} = useTranslation();
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!email) {
      setEmailErr(t("email is required"));
      return;
    }
    if (!email.match(emailRegex)) {
      setEmailErr(t("errEmailInvalid"));
      return;
    }
    setEmailErr("");
    register();
  };

  const register = () => {

    apiInterceptor.post(`/api/Registration/trainer`,{email:email} )
      .then((res) => {

        if (res.status === 200) {
          dispatch(
            SignUpFormsAction({
              email: email,
              registrationStep: "emailverification",
            })
          );

          return res.data;
        } else {
            const data = res.data;
            modalCtx.setModalType(0);
            modalCtx.setMessage(data.message);
            modalCtx.setIsActive(true);

        }
      })
      .catch((err) => {
      });
  };

  return (
    <div className="trainerLoginOrSignUpForm">
      <h1>{t("SignUp")}</h1>
      <p className={"haveAccount"}>
        {t("Already have an account?")}
        <a
          onClick={() =>
            dispatch(SignUpFormsAction({ registrationStep: "login" }))
          }
        >
          {" "}
          {t("Login")}
        </a>
      </p>
      <hr />
      <form onSubmit={handleSubmit} style={{ width: "100%" }} className={"registration"} id={"form1"}>
        <div className="form-group">
          <label htmlFor="email">
            {t("email")}{" "}
            <span className="required">*</span>
          </label>
          <input
            type="email"
            id={"email"}
            value={email}
            placeholder={t("enter email")}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailErr ? <div className="error">{emailErr}</div> : null}
        </div>
        <button type={"submit"}>
          {t("SignUp")}
        </button>
      </form>
      <p>
        <a href="https://skulp.de/datenschutz"  target={"_blank"}>
          {t("policyAndTermsOfUse")}
        </a>
      </p>
    </div>
  );
};

export default TrainerLoginOrSignUpForm;
