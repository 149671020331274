import React, { useContext } from "react";
import mailSVG from '../../../images/component_svg/mail.svg'
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";

const EMailVerificationForm = () => {
  const { i18n } = useTranslation();

    const userdata = useSelector(state => state.registration)
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const apiContext = useContext(ApiContext);
    const {apiInterceptor} = apiContext;

    const handleResendActivationEmail = async () => {

      const response = await apiInterceptor.get(`/api/Registration/resend-email/${userdata.email.replace("@","%40")}`)

      if(response.status === 200){
        const res = await response.data;
      }

    }

    return (
        <div className="emailVerificationForm">
            <h1>{t("emailVerification")}</h1>
            <img src={mailSVG} alt=""/>
            <p>{t("To_complete_the_setup_and_login")}</p>
            <p>{userdata.email}</p>
            <hr/>
            <button type={"submit"} onClick={handleResendActivationEmail}>
                {t("Resend confirmation Email")}
            </button>

        </div>

    )

}

export default EMailVerificationForm
