import React, { useContext, useEffect } from "react";
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";

import Layout from "./components/layout/Layout";
import HomePage from "./pages/HomePage";
import AuthContext from "./utility/context/auth-context";

import TrainerOnboardingPage from "./pages/TrainerOnboarding/TrainerOnboardingPage";
import TrainerOnboardingAbout from "./pages/TrainerOnboarding/TrainerOnboardingAbout";
import TrainerOnboardingSetPasswordPage from "./pages/TrainerOnboarding/TrainerOnboardingSetPasswordPage";
import TrainerOnboardingBusinessSettings from "./pages/TrainerOnboarding/TrainerOnboardingBusinessSettings";
import TrainerOnboardingKYC from "./pages/TrainerOnboarding/TrainerOnboardingKYC";
import TrainerOnboardingMarketing from "./pages/TrainerOnboarding/TrainerOnboardingMarketing";
import TrainerOnboardingCalendar from "./pages/TrainerOnboarding/TrainerOnboardingCalendar";
import TrainerProfile from "./pages/Trainer/TrainerProfile/TrainerProfile";
import TrainerPersonalSite from "./pages/Trainer/PersonalSite";
import TrainerPersonalSitePreview from "./pages/Trainer/PersonalSitePreview";
import TrainerOnboardingEditSpace from "./pages/TrainerOnboarding/EditSpace";
import TrainerBookingPage from "./pages/Trainer/Booking";
import TrainerBookingPageTwo from "./pages/Trainer/Booking/TrainerBookingPageTwo";
import { ModalProvider } from "./utility/context/modal-context";
import TrainerBusinessCenter from "./pages/TrainerBusinessCenter/TrainerBusinessCenter";
import CustomerDashboard from "./pages/CustomerDashboard";
import MarketingReferral from "./pages/MarketingReferral";
import Packages from "./pages/Trainer/Booking/PackagesAndSubscription";
import NewPassword from "./components/onboarding/TrainerOnboarding/NewPassword";
import TrainerDashboard from "./pages/TrainerDashboard/TrainerDashboard";
import { useDispatch, useSelector } from "react-redux";
import { LocalStorageUserData } from "./utility/hooks/Utils";
import { loadCustomerData } from "./redux/actions/customer/customerAction";
import PackageBookingPage from "./pages/Trainer/Booking/PackageBookingPage";
import TrainerNotFound from "./pages/TrainerNotFound/TrainerNotFound";
import Invoices from "./pages/Trainer/Invoices/Invoices";
import "./utility/i18n";
import CustomerPersonalInformation from "./pages/CustomerPersonalInformation/CustomerPersonalInformation";
import CustomerInvoices from "./pages/CustomerInvoices/CustomerInvoices";
import ReviewSection from "./pages/ReviewSection/ReviewSection";
import { getPublicTrainerData } from "./redux/actions/registration/OnboardingAction";
import i18n, { changeLanguage } from "i18next";
import PersonalSite from "./pages/Trainer/PersonalSite";
import ExternalStatusPage from "./pages/ExternalStatusPage/ExternalStatusPage";
import AdminScreen from "./pages/Admin";
import { ThankYouPage } from "./pages/ThankYou";
import { DateTime } from "luxon";
import ApiContext from "./shared/api-interceptor/api-interceptor.component";
import PrivateRoute from "./shared/private-rout/private-rout.component";
export function ScrollToTop() {
  const pathname = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}




function App() {

  const authCtx = useContext(AuthContext);
  const apiCtx = useContext(ApiContext);
  const {apiInterceptor} = apiCtx;
  const dispatch = useDispatch();

  const userData = useSelector(
    (state) => state.onboarding.userData);
  const customerData = useSelector(state => state.customer);
  const history = useHistory();
  const query = useQuery();
  let trainerCode = query.get("trainerCode");


  useEffect(() => {


    if (authCtx.role === "User") {
      dispatch(loadCustomerData(apiInterceptor,LocalStorageUserData()?.userId));
      dispatch(getPublicTrainerData(apiInterceptor,customerData?.trainer?.trainerSpace.spaceUrl));
      //TODO: Add creation date to
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "uhcyg2rp",
        name: `${customerData?.firstName} ${ customerData?.lastName}`, // Vollständiger Name
        email: JSON.parse(localStorage.getItem("userData"))?.email, // E-Mail-Adresse
        created_at: DateTime.fromISO(customerData.creationTime).toMillis() // Registrierungsdatum als Unix-Zeitstempel
      };
    }

    if(authCtx.role === "Trainer"){
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "uhcyg2rp",
        name:  `${userData?.firstName} ${ userData?.lastName}`, // Vollständiger Name
        email: JSON.parse(localStorage.getItem("userData"))?.email, // E-Mail-Adresse
        created_at: DateTime.fromISO(userData.creationTime).toMillis() // Registrierungsdatum als Unix-Zeitstempel
      };
    }

  }, [history.location.pathname.split("/")[2]],authCtx.isLoggedIn);

  useEffect(() => {
    if (localStorage.getItem("language")) {
      console.log(i18n.language);
      changeLanguage(localStorage.getItem("language"));
    } else {
      localStorage.setItem("language", i18n.language);
    }

  }, []);

  useEffect(() => {
    if (!history.location.pathname.split("/").includes("external-status")) {
      if (authCtx.isLoggedIn && authCtx?.role === "Administrator") {
        history.replace("/")
      }
      if (authCtx.role === "User" && customerData) {
        if (customerData.trainer?.trainerSpace.spaceUrl !== history?.location?.pathname.split("/")[2]) {
          let helperString = "";
          history?.location?.pathname.split("/").forEach((item, index) => {
            if (index > 2) {
              helperString += `/${item}`;
            }
          });
          history.replace(`/me/${customerData?.trainer?.trainerSpace.spaceUrl}` + helperString);
        }
      }
    }
  }, [customerData]);

/*  useEffect(() => {

    if(history?.location?.pathname.split("/")[2] == "undefined"){
      history.replace("/")
    }

  }, [history?.location?.pathname]);*/



  //Replace /trainer from dynamic link with the SpaceUrl of the target Trainer
  useEffect(() => {
    if (!!trainerCode) {
      ReroutFromLink(apiInterceptor,trainerCode, dispatch, history);
    }
  }, []);






  //TODO - Localization implementieren
  return (
    <ModalProvider>
      <Layout>
        <Switch>
          <Route path="/" exact>
            {!authCtx.isLoggedIn && <HomePage />}
            {authCtx.isLoggedIn && authCtx?.role === "Trainer" && userData.onboardingStatus >= 5 &&
              <TrainerDashboard />}
            {authCtx.isLoggedIn && authCtx?.role === "Administrator" && <AdminScreen/>}
            {authCtx.isLoggedIn && authCtx?.role === "Trainer" && userData.onboardingStatus < 5 &&
              <TrainerOnboardingPage />}
            {authCtx.isLoggedIn && authCtx?.role === "User" && <TrainerPersonalSite />}
          </Route>
          <Route exact path="/me/:id">
            <TrainerPersonalSite />
          </Route>
          <Route exact path="/me/:id/trainer-profile">
            <TrainerProfile />
          </Route>
          <Route exact path="/me/:id/trainer-booking">
            <TrainerBookingPage />
          </Route>
          <Route exact path="/me/:id/packages">
            <Packages />
          </Route>
          <Route exact path="/me/:id/trainer-bookingTwo">
            <TrainerBookingPageTwo />
          </Route>
          <Route exact path="/me/:id/verify">
            <TrainerBookingPageTwo />
          </Route>
          <Route exact path="/me/:id/book-package">
            <PackageBookingPage />
          </Route>
          <Route exact path="/trainer-personal-site-preview">
            <TrainerPersonalSitePreview />
          </Route>
          <Route path="/change-password">
            <TrainerOnboardingSetPasswordPage />
          </Route>
          <Route path="/new-password">
            <NewPassword />
          </Route>
          <Route exact path="/external-status">
            <ExternalStatusPage />
          </Route>
          <Route path="/me/:id/business-center">
            {authCtx.isLoggedIn && authCtx?.role === "Trainer" && <TrainerBusinessCenter />}
            {authCtx.isLoggedIn && authCtx?.role === "User" && <PersonalSite />}
            {!authCtx.isLoggedIn && <Redirect to="/" />}
          </Route>

          <Route path="/me/:id/business-center-calendar">
            {authCtx.isLoggedIn && authCtx?.role === "Trainer" && <TrainerBusinessCenter />}
            {authCtx.isLoggedIn && authCtx?.role === "User" && <PersonalSite />}
            {!authCtx.isLoggedIn && <PersonalSite />}
          </Route>
          <Route path="/registration-process">
            {authCtx.isLoggedIn && <TrainerOnboardingPage />}
            {!authCtx.isLoggedIn || userData.onboardingStatus === 5 && <Redirect to="/" />}
          </Route>
          <Route path="/registration-process-about">
            {authCtx.isLoggedIn && <TrainerOnboardingAbout />}
            {!authCtx.isLoggedIn || userData.onboardingStatus === 5 && <Redirect to="/" />}
          </Route>
          <Route path="/registration-process-business-settings">
            {authCtx.isLoggedIn && <TrainerOnboardingBusinessSettings />}
            {!authCtx.isLoggedIn || userData.onboardingStatus === 5 && <Redirect to="/" />}
          </Route>
          <Route path="/registration-process-edit-your-space">
            {authCtx.isLoggedIn && <TrainerOnboardingEditSpace />}
            {!authCtx.isLoggedIn || userData.onboardingStatus === 5 && <Redirect to="/" />}
          </Route>
          <Route path="/registration-process-payment">
            {authCtx.isLoggedIn && <TrainerOnboardingKYC />}
            {!authCtx.isLoggedIn || userData.onboardingStatus === 5 && <Redirect to="/" />}
          </Route>
          <Route path="/registration-process-marketing">
            {authCtx.isLoggedIn && <TrainerOnboardingMarketing />}
            {!authCtx.isLoggedIn && <Redirect to="/" />}
          </Route>
          <Route path="/registration-process-calendar">
            {authCtx.isLoggedIn && <TrainerOnboardingCalendar />}
            {!authCtx.isLoggedIn && <Redirect to="/" />}
          </Route>
          <Route path="/me/:id/trainer-dashboard" component={TrainerDashboard}>
            {authCtx.isLoggedIn && authCtx?.role === "Trainer" && <TrainerDashboard />}
            {!authCtx.isLoggedIn && <Redirect to="/" />}
          </Route>
          <Route path="/me/:id/business-center-invoices" component={TrainerBusinessCenter}>
            {authCtx.isLoggedIn && authCtx?.role === "Trainer" && <TrainerBusinessCenter />}
            {authCtx.isLoggedIn && authCtx?.role === "User" && <PersonalSite />}
            {!authCtx.isLoggedIn && <PersonalSite />}
          </Route>
          <Route path="/me/:id/invoices" component={TrainerDashboard}>
            {authCtx.isLoggedIn && authCtx?.role === "Trainer" && <Invoices />}
            {!authCtx.isLoggedIn && <Redirect to="/" />}
          </Route>
          <Route path="/me/:id/:userId/customer-dashboard">
            {authCtx.isLoggedIn && authCtx?.role === "User" && <CustomerDashboard />}
            {!authCtx.isLoggedIn && <Redirect to="/" />}
          </Route>
          <Route path="/me/:id/redirect-dashboard">
            {authCtx.isLoggedIn && authCtx?.role === "User" && <CustomerDashboard />}
            {authCtx.isLoggedIn && authCtx?.role === "Trainer" && <TrainerBusinessCenter />}
            {!authCtx.isLoggedIn && <PersonalSite />}
          </Route>
          <Route path="/me/:id/:userId/customer-personal-information">
            {authCtx.isLoggedIn && authCtx?.role === "User" && <CustomerPersonalInformation />
            }
            {!authCtx.isLoggedIn && <Redirect to="/" />}
          </Route>
          <Route path="/me/:id/:userId/customer-invoices">
            {authCtx.isLoggedIn && authCtx?.role === "User" && <CustomerInvoices />
            }
            {!authCtx.isLoggedIn && <Redirect to="/" />}
          </Route>
          <Route path="/me/:id/:userId/rate-your-trainer">
            {authCtx.isLoggedIn && authCtx?.role === "User" && <ReviewSection />
            }
            {!authCtx.isLoggedIn && <Redirect to="/" />}
          </Route>
          <Route path="/marketing-referral" component={MarketingReferral} />
          <Route exact path="/stripe-success">
            <ThankYouPage />
          </Route>
          <Route path="/trainerNotFound">
            <TrainerNotFound />
          </Route>
          <Route path="/test">
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Layout>

    </ModalProvider>
  );
}

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ReroutFromLink = async (apiInterceptor,trainerCode, dispatch, history) => {
  const url = `/api/trainers/trainer-code/${trainerCode}/public`;



  const response = await apiInterceptor.get(url);

  if (response.status === 200) {
    const res =  response.data;
    const location = history.location.pathname;
    const editLocation = location.replace("/trainer", `/${res.data.trainerSpace.spaceUrl}`);
    history.replace(editLocation);
    dispatch(getPublicTrainerData(apiInterceptor,res.data.trainerSpace.spaceUrl));
  } else {
    history.replace("/trainerNotFound");
  }

};

export default App;


// http://localhost:3000/me/trainer/?trainerCode=YAX4ET6V
// history?.location?.pathname.split("/")[2]
// http://localhost:3000/new-password?code=a0309606-253c-424c-b8c7-e43f656c9e31&userId=da15965e-f8ee-45c6-adc3-ccb434671014


{/*  <Switch>
          <Route path="/" exact>
            {!authCtx.isLoggedIn && <HomePage />}
            {authCtx.isLoggedIn && authCtx?.role === "Trainer" && userData.onboardingStatus >= 5 &&
              <TrainerDashboard />}
            {authCtx.isLoggedIn && authCtx?.role === "Administrator" && <AdminScreen/>}
            {authCtx.isLoggedIn && authCtx?.role === "Trainer" && userData.onboardingStatus < 5 &&
              <TrainerOnboardingPage />}
            {authCtx.isLoggedIn && authCtx?.role === "User" && <TrainerPersonalSite />}
          </Route>


          <Route exact path={"/"}>
            <HomePage />
          </Route>
          <Route exact path="/me/:id">
            <TrainerPersonalSite />
          </Route>
          <Route exact path="/me/:id/trainer-profile">
            <TrainerProfile />
          </Route>
          <Route exact path="/me/:id/trainer-booking">
            <TrainerBookingPage />
          </Route>
          <Route exact path="/me/:id/packages">
            <Packages />
          </Route>
          <Route exact path="/me/:id/trainer-bookingTwo">
            <TrainerBookingPageTwo />
          </Route>
          <Route exact path="/me/:id/verify">
            <TrainerBookingPageTwo />
          </Route>
          <Route exact path="/me/:id/book-package">
            <PackageBookingPage />
          </Route>
          <Route exact path="/trainer-personal-site-preview">
            <TrainerPersonalSitePreview />
          </Route>
          <Route path="/change-password">
            <TrainerOnboardingSetPasswordPage />
          </Route>
          <Route path="/new-password">
            <NewPassword />
          </Route>
          <Route exact path="/external-status">
            <ExternalStatusPage />
          </Route>

          <PrivateRoute
            path="/me/:id/business-center"
            role={"trainer"}
            isOnboarded={true}
            component={TrainerBusinessCenter}/>

          <PrivateRoute
            path="/me/:id/business-center-calendar"
            role={"trainer"}
            isOnboarded={true}
            component={TrainerBusinessCenter}/>
          <PrivateRoute
            path="/registration-process"
            role={"trainer"}
            component={TrainerOnboardingPage}/>
          <PrivateRoute
            path="/registration-process-about"
            role={"trainer"}
            component={TrainerOnboardingAbout}/>
          <PrivateRoute
            path="/registration-process-business-settings"
            role={"trainer"}
            component={TrainerOnboardingBusinessSettings}
          />
           <PrivateRoute
            path="/registration-process-edit-your-space"
            role={"trainer"}
            component={TrainerOnboardingEditSpace}
          />
          <PrivateRoute
            path="/registration-process-payment"
            role={"trainer"}
            component={TrainerOnboardingKYC}
          />
          <PrivateRoute
            path="/registration-process-marketing"
            role={"trainer"}
            component={TrainerOnboardingMarketing}
          />
          <PrivateRoute
            path="/registration-process-calendar"
            role={"trainer"}
            component={TrainerOnboardingCalendar}
          />
          <PrivateRoute
            path="/me/:id/trainer-dashboard"
            role={"trainer"}
            isOnboarded={true}
            component={TrainerDashboard}
          />
          <PrivateRoute
            path="/me/:id/business-center-invoices"
            role={"trainer"}
            isOnboarded={true}
            component={TrainerBusinessCenter}
          />

          <PrivateRoute
            path="/me/:id/:userId/customer-dashboard"
            role={"customer"}
            component={CustomerDashboard}
          />
          <Route path="/me/:id/redirect-dashboard">
            {authCtx.isLoggedIn && authCtx?.role === "User" && <CustomerDashboard />}
            {authCtx.isLoggedIn && authCtx?.role === "Trainer" && <TrainerBusinessCenter />}
            {!authCtx.isLoggedIn && <PersonalSite />}
          </Route>
          <PrivateRoute
            path="/me/:id/:userId/customer-personal-information"
            role={"customer"}
            component={CustomerPersonalInformation}
          />
          <PrivateRoute
            path="/me/:id/:userId/customer-invoices"
            role={"customer"}
            component={CustomerInvoices}
          />
          <PrivateRoute
            path="/me/:id/:userId/rate-your-trainer"
            role={"customer"}
            component={ReviewSection}
          />

          <Route path="/marketing-referral" component={MarketingReferral} />
          <Route exact path="/stripe-success">
            <ThankYouPage />
          </Route>
          <Route path="/trainerNotFound">
            <TrainerNotFound />
          </Route>
          <Route path="/test">
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>*/}
