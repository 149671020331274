export enum phoneNumberCountryCode {}

export enum spokenLanguagesEnum {
  English,
  French,
  German,
}

export enum educationEnum {
  None,
  Bachelor,
  Master,
  Other
}

export enum difficultyLevelEnum {
  basic,
  advanced,
  expert,
}

export enum cancelationPoliciesTimeRangeEnum {
  "1-12",
  "24-36",
  "36-48",
  "48-62",
  ">62",
}

export enum ModalEnum {
  InfoModal,
  LoginModal,
  ConfirmationModal,
  CongratulationsModal
}

export enum feeTypeEnum {
  "0 %",
  "0%",
  "8%",
  "4%",
}

export enum feeTypeValue {
  NoFees,

  PaidByTrainer,

  PaidByCustomer,

  Shared
}

export enum countrysEnum {
  Germany = 4,
  Luxembourg= 5,
  Austria= 8,
  Switzerland= 10,
}

export enum countrysEnum_backup {
  Belgium,
  Denmark,
  Finland,
  France,
  Germany,
  Luxembourg,
  Netherlands,
  Norway,
  Austria,
  Sweden,
  Switzerland,
  UK,
}

export enum currencyEnum {
  EUR,
  GBP,
  USD,
  CHF,
  AED,
  SEK,
  NOK,
  DKK,
}

export enum currencySymbolEnum {
  EUR = "€",
  GBP = "£",
  USD = "$",
  CHF = "CHF",
  AED = "AED",
  SEK = "Skr",
  NOK = "Nkr",
  DKK = "Dkr",
}

export enum currencySymbolEnumFromIndex {
   "€",
  "£",
  "$",
  "CHF",
  "د.إ",
  "Skr",
  "Nkr",
  "Dkr",
}

export enum serviceTypeEnum {
  virtual,
  onSiteCoach,
  onSiteClient,
}

export enum ServiceCategoryEnum {
  private = 0,
  smallGroup = 1
}

export enum CompanyTypeRequest
{
  None = 0,
  Einzelunternehmen = 1,
  Kapitalgesellschaft = 2,
  Personengesellschaft = 3
}

export const trainingDurations = [
  { name: "45 minutes", value: 0 },
  { name: "1 hour", value: 1 },
  { name: "1 hour and 30 minutes", value: 2 },
  { name: "2 hours", value: 3 },
  { name: "3 hours", value: 4 },
  { name: "5 hours", value: 5 }
];

export const trainingLocations = [
  { name: "Train on my own address", value: 0 },
  { name: "Parks near me", value: 1 },
  { name: "Choose one of the trainers favorite place", value: 2 }
];

export enum timeZoneEnum {
  "-11:00 Pago Pago",
  "-10:00 Honolulu",
  "-08:00 Los Angeles, Tijuana",
  "-07:00 Denver, Phoenix, Mazatlan",
  "-06:00 Chicago, Mexico City, Regina, Guatemala",
  "-05:00 New York, Lima, Bogota",
  "-04:30 Caracas",
  "-04:00 Halifax, Guyana, LaPaz",
  "-03:00 Buenos Aires, Godthab, Montevideo, Santiago",
  "-03:30 St. Johns",
  "-02:00 Sao Paulo, South Georgia",
  "-01:00 Azores, Cape Verde",
  "+00:00 Dublin, Lisbon, London, Casablanca, Monrovia",
  "+01:00 Berlin, Paris, Brussels, Amsterdam, Budapest",
  "+02:00 Athens, Helsinki, Kiev, Cairo, Johannesburg, Jerusalem",
  "+03:00 Istanbul, Moscow, Baghdad, Riyadh, Nairobi",
  "+03:30 Tehran",
  "+04:00 Baku, Tbilisi, Yerevan, Samara",
  "+04:30 Kabul",
  "+05:00 Karachi, Tashkent, Yekaterinburg",
  "+05:30 Colombo",
  "+06:00 Dhaka, Almaty",
  "+06:30 Rangoon",
  "+07:00 Bangkok, Jakarta, Krasnoyarsk",
  "+08:00 Hong Kong, Shanghai, Singapore, Perth, Kuala Lumpur",
  "+09:00 Seoul, Tokyo, Yakutsk",
  "+09:30 Darwin",
  "+10:00 Brisbane, Vladivostok, Guam, Magadan",
  "+10:30 Adelaide",
  "+11:00 Sydney, Hobart, Guadalcanal, Noumea",
  "+12:00 Majuro, Kamchatka",
  "+13:00 Auckland, Fakaofo, Fiji",
  "+14:00 Apia",
}

export const daysAndValues = [
  { key: "Monday", value: 1 },
  { key: "Tuesday", value: 2 },
  { key: "Wednesday", value: 3 },
  { key: "Thursday", value: 4 },
  { key: "Friday", value: 5 },
  { key: "Saturday", value: 6 },
  { key: "Sunday", value: 0 }
];

export enum imageUploadType {
  "TrainerProfile",
  "TrainerSpace",
  CustomerProfile,
}

export enum serviceGroupType {
  "on site",
  "on site coach"
}

export const durationInMinutes = [30, 45, 60, 90];

export enum PlatformType {
  'Undefined' = '0',
  'Web'= '1',
  'Android' = '2',
  'iOS' = '3'
}

export const PackageLimit = 200;

export const emailRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/;

export const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#!$%]).{8,20}/;
