import { ReactComponent as SearchSvg } from "../../../../images/component_svg/search_white.svg";
import { ReactComponent as ArrowSvg } from "../../../../images/component_svg/arrowDownSVG.svg";
import { ReactComponent as DeleteSvg } from "../../../../images/component_svg/delete.svg";
import { useTranslation } from "react-i18next";
import CRMTableRow from "./CRMTableRow";
import { Dispatch, useContext, useEffect, useState } from "react";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import ApiContext from "../../../../shared/api-interceptor/api-interceptor.component";


const Crm = () => {

    const userData = useSelector((state) => state.onboarding.userData);
    const dispatch = useDispatch();
    const [customerCredits, setCustomerCredits] = useState([]);
    const apiContext = useContext(ApiContext);
    const {apiInterceptor} = apiContext;

    const getCustomerCredits = async () => {
      const url = `/api/trainers/customers-credits`;
      const response = await apiInterceptor.get(url);
      if (response.status === 200) {
        setCustomerCredits(response.data);
      } else {
      }
    };


    useEffect(() => {
      getCustomerCredits();

    }, []);

    const { t, i18n } = useTranslation();
    return (
      <div className="crm">
        <div className="card">
          <div className="card_content">
            <div className="card_heading">
              {/*<input id={"search"} type="text" name={"search"} placeholder={t("search name")} />
          <button type={"submit"}><SearchSvg /></button>*/}
            </div>

            <div className="crm-table">
              <div className="table-header">
                <div className="muted">{t("clients")}</div>
                <div className="muted">{t("open credits")}</div>
                <div className="muted">{t("date")}</div>
              </div>
              {
                customerCredits.map((item: any) => {
                  return (
                    <CRMTableRow getCustomerCredits={getCustomerCredits} item={item} />
                  );
                })
              }


            </div>

          </div>
        </div>
      </div>
    );

  }
;
export default Crm;
