import React, { useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { InvoiceItemType } from "../../redux/reducers/booking/bookingReducer";
import InvoiceItem from "../Trainer/Invoices/InvoiceItem";
import { changeNavigation } from "../../redux/actions/layout/layoutAction";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import i18n from "i18next";
import de from "date-fns/locale/de";
import ApiContext from "../../shared/api-interceptor/api-interceptor.component";

const CustomerInvoices = () => {
  const [dateFrom,setDateFrom] = useState(DateTime.now().minus({day: 14}).set({hour:0,minute:0,second:0}));
  const [dateTo,setDateTo] = useState(DateTime.now().set({hour:0,minute:0,second:0}));
  const [invoices,setInvoices] = useState<Array<any>>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  const getInvoices =  async () => {
    try {

      const url = `/api/customers/invoices?dateFrom=${dateFrom.toUTC().toISO()}&dateTo=${dateTo.toUTC().toISO()}`;
      const response = await apiInterceptor.get(url);

      if (response.status === 200) {
        setInvoices(response.data);
      }
    } catch (err) {
    }
  };

  useEffect(() => {
    dispatch(changeNavigation(1));

  },[])

  useEffect(() => {
    getInvoices();
  },[dateFrom,dateTo])

  return(
    <div className="initialView">
    <div className="customerInvoices">
      <div className="invoices">
        <div className="invoicesHeader">
          <h1>{t("invoices")}</h1>
          <hr />
        </div>
        <div className="card">
          <div className="date-range-picker">
            <DatePicker
              id={"dateFrom"}

              onChange={(date) => {
                if(date !== null){
                  setDateFrom(DateTime.fromJSDate(date))
                }
              }}
              selected={dateFrom.toJSDate()}
              dateFormat="dd.MM.yyyy"
              locale={i18n.language === "de" ? de : ""}
              calendarStartDay={1}
            />
            <span className={"fromTo"}> - </span>
            <DatePicker
              id={"dateTo"}

              onChange={(date) => {
                if(date !== null){
                  setDateTo(DateTime.fromJSDate(date))
                }
              }}
              selected={dateTo.toJSDate()}
              dateFormat="dd.MM.yyyy"
              locale={i18n.language === "de" ? de : ""}
              calendarStartDay={1}
            />
          </div>



          <div className="invoicesTable">
            {   invoices.map((item: InvoiceItemType) => {
              return <InvoiceItem key={item.invoiceNumber} isCustomer={true}  item={item} />
            })}
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}
export default CustomerInvoices
