import React, { useContext, useEffect, useState } from "react";
import TrainerOnboardingProcessBusinessTimezoneCurrency from "../../components/onboarding/TrainerOnboarding/TrainerOnboardingProcessBusinessTimezoneCurrency";
import TrainerOnboardingProcessBusinessCancellationPolicies from "../../components/onboarding/TrainerOnboarding/TrainerOnboardingProcessBusinessCancellationPolicies";
import TrainerOnboardingProcessBusinessSeviceFee from "../../components/onboarding/TrainerOnboarding/TrainerOnboardingProcessBusinessSeviceFee";
import { useDispatch } from "react-redux";
import {
  setOnboardingStatusAction,
  setTrainerData,
} from "../../redux/actions/registration/OnboardingAction";
import TrainerOnboardingProcessBusinessTrainingsOptions from "../../components/onboarding/TrainerOnboarding/TrainerOnboardingProcessBusinessTrainingsOptions";
import TrainerOnboardingBottomBar from "../../components/onboarding/TrainerOnboarding/TrainerOnboardingBottomBar";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ApiContext from "../../shared/api-interceptor/api-interceptor.component";

const TrainerOnboardingMarketing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [inputErr, setInputErr] = useState("");
  const [inputMarketing, setInputMarketing] = useState("");
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;

  useEffect(() => {
    dispatch(setTrainerData(apiInterceptor));
    dispatch(setOnboardingStatusAction(5));
  }, []);
  const history = useHistory();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setInputErr("");
    if (!inputMarketing) {
      setInputErr(t("errRequiredFiled"));
      return;
    }
    history.replace("/registration-process-calendar");
  };

  const handlePreviousStep = () => {
    history.replace("/registration-process-payment");
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    setInputMarketing(value);
  };
  return (
    <div className={"initialView"}>
      <div className="content">
        <div className="pageTitle">
          <h1>Marketing</h1>
          <hr />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="trainerOnboardingTestSection">
            <div className="card">
              <div className="card_content">
                <label htmlFor="requiredField">Required Field</label>
                <input
                  id={"requiredField"}
                  value={inputMarketing}
                  placeholder={"required Field Type something"}
                  onChange={handleChange}
                />
                {inputErr && !inputMarketing && (
                  <div className="validation-error yellow-global col-12 mt-2">
                    {inputErr}
                  </div>
                )}
              </div>
            </div>
          </div>
          <TrainerOnboardingBottomBar handlePreviousStep={handlePreviousStep} />
        </form>
      </div>
    </div>
  );
};

export default TrainerOnboardingMarketing;
