import React, { useContext, useEffect, useState } from "react";
import TrainerOnboardingProcessBusinessDisciplines from "../../onboarding/TrainerOnboarding/TrainerOnboardingProcessBusinessDisciplines";
import TrainerOnboardingProcessBusinessTimezoneCurrency from "../../onboarding/TrainerOnboarding/TrainerOnboardingProcessBusinessTimezoneCurrency";
import TrainerOnboardingProcessBusinessCancellationPolicies from "../../onboarding/TrainerOnboarding/TrainerOnboardingProcessBusinessCancellationPolicies";
import TrainerOnboardingProcessBusinessSeviceFee from "../../onboarding/TrainerOnboarding/TrainerOnboardingProcessBusinessSeviceFee";
import TrainerOnboardingProcessBusinessTrainingsOptions from "../../onboarding/TrainerOnboarding/TrainerOnboardingProcessBusinessTrainingsOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  createObjectArrayFromStringArray,
  createStringArrayFromObjectArray,
} from "../../../utility/hooks/Utils";
import { setTrainerData } from "../../../redux/actions/registration/OnboardingAction";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../utility/context/auth-context";
import ModalContext from "../../../utility/context/modal-context";
import { useTranslation } from "react-i18next";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";

const intErrObject = {
  timzone: "",
  currency: "",
  cancellationAllowed: "",
  onSite: "",
  onSiteCoach: "",
  address: "",
  housenumber: "",
  postalCode: "",
  city: "",
  country: "",
  trainerBreakTime: "",
  virtualBreakTime: "",
  feeType: "",
  onSiteClient: "",
  numberOfKm: "",
  travelExpenses: "",
  clientWorkoutBreakTime: "",
  isVirtual: "",
  fullRefundValue: "",
  halfRefundValue: "",
};

const BusinessSettings = () => {
  const userData = useSelector((state) => state.onboarding.userData);
  const { i18n, t } = useTranslation();
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  const [specialties, setSpecialties] = useState(
    createObjectArrayFromStringArray(userData?.disciplines)
  );
  const [timezone, setTimezone] = useState(
    userData?.timezone !== null ? userData?.timezone : 4
  );
  const [currency, setCurrency] = useState(
    userData?.currency !== null ? userData?.currency : 4
  );
  const [errObject, setErrObject] = useState<any>(intErrObject);
  const [specialtiesErr, setSpecialtiesErr] = useState<string>("");
  //canellation Policies
  const [cancelltionAllowed, setCancellationAllowed] = useState<number>(
    userData?.isCancellationAllowed === false ? 0 : 1 || 1
  );
  const [fullRefundisActive, setFullRefundisActive] = useState<boolean>(
    userData?.cancellation100_Deadline === 0 ? false : true || true
  );
  const [halfRefundisActive, setHalfRefundisActive] = useState<boolean>(
    userData?.cancellation50_Deadline === 0 ? false : true || false
  );
  const [fullRefundValue, setFullRefundValue] = useState(
    userData?.cancellation100_Deadline || 0
  );
  const [halfRefundValue, setHalfRefundValue] = useState(
    userData?.cancellation50_Deadline || 0
  );
  //Service Feeg
  const [feeType, setFeeType] = useState(userData?.feeType || 2);
  //TrainerOptions
  const [travelExpenses, setTravelExpanses] = useState<Number>(
    Number(userData?.clientWorkoutTravelExpense) || 0
  );
  const [numberOfKm, setNumberOfKm] = useState<number>(
    userData?.perimeterRadiusKm || 1
  );
  //onSite
  const [onSite, setOnsite] = useState<number>(
    userData?.onSite === false ? 0 : 1 || 1
  );
  const [onSiteCoach, setOnsiteCoach] = useState<number>(
    userData?.onSiteCoach === false ? 0 : 1 || 1
  );
  const [onSiteClient, setOnsiteClient] = useState<number>(
    userData?.onSiteClient === false ? 0 : 1 || 1
  );
  //Homebase State
  const [address, setAddress] = useState(userData?.trainerWorkoutAddress || "");
  const [postalCode, setPostalCode] = useState(
    userData?.trainerWorkoutPostalCode || ""
  );
  const [city, setCity] = useState(userData?.trainerWorkoutCity || "");
  const [country, setCountry] = useState(userData?.trainerWorkoutCountry || 4);
  const [housenumber, setHousenumber] = useState(
    userData?.trainerWorkoutHouseNumber || ""
  );
  const [trainerWorkoutBreakTime, setTrainerWorkoutBreakTime] = useState(
    userData?.trainerWorkoutBreakTime || 0
  );
  const [clientWorkoutBreakTime, setClientWorkoutBreakTime] = useState(
    userData?.clientWorkoutBreakTime || 0
  );
  //Virtual
  const [virtualLink, setVirtualLink] = useState(userData?.virtualLink || "");
  const [virtualBreakTime, setVirtualBreakTime] = useState(
    userData?.virtualBreakTime || 0
  );
  const [isVirtual, setIsVirtual] = useState<number>(
    userData?.isVirtual === false ? 0 : 1 || 1
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTrainerData(apiInterceptor));
  }, []);
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext<any>(ModalContext);
  // Validation all required inputs
  const handleValidateInputs = async (): Promise<boolean> => {
    return new Promise(async (resolve) => {
      let status = true;
      const errros = JSON.parse(JSON.stringify(intErrObject));
      setSpecialtiesErr("");
      if (specialties.length < 1) {
        setSpecialtiesErr(t("errSpecialtiesInput"));
        status = false;
      }
      //time zone validation
      if (timezone === null) {
        errros.timzone = t("errTimeZone");
        status = false;
      }
      if (currency === null) {
        errros.currency = t("errCurrency");
        status = false;
      }

      if (cancelltionAllowed === null) {
        errros.cancellationAllowed = t(
          "errCancellationAllowed"
        );
        status = false;
      } else {
        if (cancelltionAllowed === 1) {
          if (
            fullRefundisActive === true &&
            (!fullRefundValue || fullRefundValue === null)
          ) {
            errros.fullRefundValue = t("errDeadLine");
            status = false;
          }
          if (
            halfRefundisActive === true &&
            (!halfRefundValue || halfRefundValue === null)
          ) {
            errros.halfRefundValue = t("errDeadLine");
            status = false;
          }
        }
      }

      if (feeType === null || feeType === 0) {
        errros.feeType = t("errFeeType");
        status = false;
      }

      // if onsite type not selected
      if (onSite === null) {
        errros.onSite = t("errOnSite");
        status = false;
      } else {
        //if onsite selected === 1 and coach type not selected
        if (onSite === 1 && onSiteCoach === null) {
          errros.onSiteCoach = t("errOnSiteCoach");
          status = false;
        } else {
          //if onsite selected === 1 and onSiteCoach selected === 1
          if (onSite === 1 && onSiteCoach === 1) {
            if (!address) {
              errros.address = t("address is required");
              status = false;
            }
            if (!housenumber) {
              errros.housenumber = t("house number is required");
              status = false;
            }
            if (!postalCode) {
              errros.postalCode = t(
                "postal code is required"
              );
              status = false;
            }
            if (!city) {
              errros.city = t("errCity");
              status = false;
            }
            if (country === null) {
              errros.country = t("choose your country");
              status = false;
            }
            if (trainerWorkoutBreakTime === null) {
              errros.trainerBreakTime = t(
                "errTrainerBreakTime"
              );
              status = false;
            }
          }
        }
      }
      // if on site client not selected
      if (onSiteClient === null) {
        errros.onSiteClient = t("errOnSiteClient");
        status = false;
      } else {
        if (onSiteClient === 1 && numberOfKm === null) {
          errros.numberOfKm = t("errRequiredFiled");
          status = false;
        }
        if (onSiteClient === 1 && travelExpenses < 0) {
          errros.travelExpenses = t("errKmFiled");
          status = false;
        }
        if (onSiteClient === 1 && isNaN(Number(travelExpenses.toString().replace(",",".")))) {
          errros.travelExpenses = t(
            "errKmFiled"
          );
          status = false;
        }
        if(onSiteClient === 1 && travelExpenses.toString().replace(",", ".").split(".")[1]?.length > 1  ){
          status=false;
        }

        if (onSiteClient === 1 && clientWorkoutBreakTime === null) {
          errros.clientWorkoutBreakTime = t(
            "errRequiredFiled"
          );
          status = false;
        }
      }

      // if virtual type not selected
      if (isVirtual === null) {
        errros.isVirtual = t("errIsVirtual");
        status = false;
      } else {
        if (isVirtual === 1 && virtualBreakTime === null) {
          errros.virtualBreakTime = t(
            "errVirtualBreakTime"
          );
          status = false;
        }
        if (isVirtual === 1 && virtualLink === "") {
          errros.virtualLink = t(
            "errVirtualLink"
          );
          status = false;
        }
      }
      setErrObject(errros);
      resolve(status);
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const isFormValid = await handleValidateInputs();
    if (!isFormValid) {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("please fill all the required fields"));
      modalCtx.setIsActive(true);
      return;
    }
    const data = {
      ...userData,
      onboardingStatus: 5,
      disciplines: createStringArrayFromObjectArray(specialties),
      timezone: timezone,
      currency: Number(currency),
      IsCancellationAllowed: Boolean(cancelltionAllowed),
      cancellation100_Deadline: fullRefundValue,
      cancellation50_Deadline: halfRefundValue,
      feeType: feeType,
      onSite: Boolean(onSite),
      onSiteCoach: Boolean(onSiteCoach),
      onSiteClient: Boolean(onSiteClient),
      PerimeterRadiusKm: numberOfKm,
      trainerWorkoutHouseNumber: housenumber,
      trainerWorkoutAddress: address,
      trainerWorkoutCity: city,
      trainerWorkoutPostalCode: postalCode,
      trainerWorkoutCountry: Number(country),
      trainerWorkoutBreakTime: trainerWorkoutBreakTime,
      clientWorkoutTravelExpense: Number(travelExpenses.toString().replace(",",".")),
      clientWorkoutBreakTime: clientWorkoutBreakTime,
      isVirtual: Boolean(isVirtual),
      virtualLink: virtualLink,
      virtualBreakTime: virtualBreakTime,
    };
    if (
      halfRefundisActive &&
      fullRefundisActive &&
      halfRefundValue === 0 &&
      fullRefundValue === 0
    ) {
      //TODO: Message : "Please Choose a Cancellation deadline for 50% refund")"
    } else {

      await apiInterceptor.put(`/api/trainers/user`,data)
        .then(async (data) => {

          if (data.status === 200) {
            dispatch(setTrainerData(apiInterceptor));
            modalCtx.setModalType(0);
            modalCtx.setMessage(data.data.message);
            modalCtx.setIsActive(true);
          } else {
            modalCtx.setModalType(0);
            modalCtx.setMessage(data.data.message);
            modalCtx.setIsActive(true);
          }
        })
        .catch((e) => console.log(e));

    }
  };

  useEffect(() => {
    //init values after reload
    setSpecialties(createObjectArrayFromStringArray(userData?.disciplines))
    setTimezone(userData?.timezone !== null ? userData?.timezone : 13)
    setCurrency(userData?.currency !== null ? userData?.currency : 0)
    setCancellationAllowed(userData?.isCancellationAllowed === false ? 0 : 1 || 1)
    setFullRefundisActive(userData?.cancellation100_Deadline === 0 ? false : true || true)
    setHalfRefundisActive(userData?.cancellation50_Deadline === 0 ? false : true || false)
    setFullRefundValue(userData?.cancellation100_Deadline || 0)
    setHalfRefundValue(userData?.cancellation50_Deadline || 0)

    setFeeType(userData?.feeType || 2)
    setTravelExpanses(Number(userData?.clientWorkoutTravelExpense) || 0)
    setNumberOfKm(userData?.perimeterRadiusKm || 1)

    setOnsite(userData?.onSite === false ? 0 : 1 || 1)
    setOnsiteCoach(userData?.onSiteCoach === false ? 0 : 1 || 1)
    setOnsiteClient(userData?.onSiteClient === false ? 0 : 1 || 1)

    setAddress(userData?.trainerWorkoutAddress || "")
    setPostalCode(userData?.trainerWorkoutPostalCode || "")
    setCity(userData?.trainerWorkoutCity || "")
    setCountry(userData?.trainerWorkoutCountry || 4)
    setHousenumber(userData?.trainerWorkoutHouseNumber || "")

    setTrainerWorkoutBreakTime(userData?.trainerWorkoutBreakTime || 0)
    setClientWorkoutBreakTime(userData?.clientWorkoutBreakTime || 0)

    setVirtualLink(userData?.virtualLink || "")
    setVirtualBreakTime(userData?.virtualBreakTime || 0)
    setIsVirtual(userData?.isVirtual === false && (!userData?.onSiteCoach === false || !userData?.onSiteClient === false) ? 0 : 1 || 1)

  },[userData.id])

  return (
    <div className="businessCenterService">
      <div className="businessCenterHeader">
        <h1>{t("businessSettingsCapital")}</h1>
        <hr />
      </div>
      <form onSubmit={handleSubmit}>
        <TrainerOnboardingProcessBusinessDisciplines
          specialties={specialties}
          setSpecialties={setSpecialties}
          specialtiesErr={specialtiesErr}
        />
        <TrainerOnboardingProcessBusinessTimezoneCurrency
          timezone={timezone}
          setTimezone={setTimezone}
          currency={currency}
          setCurrency={setCurrency}
          errObject={errObject}
          disableCurrency={true}
        />
        <TrainerOnboardingProcessBusinessCancellationPolicies
          cancelltionAllowed={cancelltionAllowed}
          setCancellationAllowed={setCancellationAllowed}
          fullRefundisActive={fullRefundisActive}
          setFullRefundisActive={setFullRefundisActive}
          halfRefundisActive={halfRefundisActive}
          setHalfRefundisActive={setHalfRefundisActive}
          fullRefundValue={fullRefundValue}
          setFullRefundValue={setFullRefundValue}
          halfRefundValue={halfRefundValue}
          setHalfRefundValue={setHalfRefundValue}
          errObject={errObject}
        />
        <TrainerOnboardingProcessBusinessSeviceFee
          feeType={feeType}
          setFeeType={setFeeType}
          errObject={errObject}
        />
        <TrainerOnboardingProcessBusinessTrainingsOptions
          currency={currency}
          travelExpenses={travelExpenses}
          setTravelExpanses={setTravelExpanses}
          numberOfKm={numberOfKm}
          setNumberOfKm={setNumberOfKm}
          onSite={onSite}
          setOnsite={setOnsite}
          onSiteCoach={onSiteCoach}
          setOnsiteCoach={setOnsiteCoach}
          onSiteClient={onSiteClient}
          setOnsiteClient={setOnsiteClient}
          address={address}
          setAddress={setAddress}
          postalCode={postalCode}
          setPostalCode={setPostalCode}
          city={city}
          setCity={setCity}
          housenumber={housenumber}
          setHousenumber={setHousenumber}
          country={country}
          setCountry={setCountry}
          trainerWorkoutBreakTime={trainerWorkoutBreakTime}
          setTrainerWorkoutBreakTime={setTrainerWorkoutBreakTime}
          clientWorkoutBreakTime={clientWorkoutBreakTime}
          setClientWorkoutBreakTime={setClientWorkoutBreakTime}
          virtualLink={virtualLink}
          setVirtualLink={setVirtualLink}
          virtualBreakTime={virtualBreakTime}
          setVirtualBreakTime={setVirtualBreakTime}
          isVirtual={isVirtual}
          setIsVirtual={setIsVirtual}
          errObject={errObject}
          isSubmitButton={true}
        />
      </form>
    </div>
  );
};
export default BusinessSettings;
