import exp from "constants";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect } from "react";
import { getPublicTrainerData } from "../../../redux/actions/registration/OnboardingAction";
import { useHistory } from "react-router-dom";
import { changeNavigation } from "../../../redux/actions/layout/layoutAction";
import SubscriptionAndPackageItem from "./SubscriptionAndPackageItem";
import { getPackages } from "../../../redux/actions/packages/packagesAction";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";


export type SubscriptionAndPackageItemType = {
  name: string,
  price: number,
  category: number,
  creditsAmount: number,
  disciplines: Array<string>,
  description: string,
}

const PackagesAndSubscription = () => {
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);
  const dispatch = useDispatch();
  const history = useHistory();
  const packages = useSelector(state => state.package.packages);
  const { t } = useTranslation();

  const [cookiesSelectedTime, setCookieSelectedTime, removeCookieSelectedTime] = useCookies(["SelectedTimeCookie", "travelData"]);
  const [cookiesSelectedPackage, setCookieSelectedPackage, removeCookieSelectedPackage] = useCookies(["SelectedPackageCookie","SelectedPackageForBooking"]);
  const [cookiesSelectedForBooking, setCookieSelectedForBooking, removeCookieSelectedForBooking] = useCookies(["SelectedForBookingCookie"]);

  useEffect(() => {
    removeCookieSelectedForBooking("SelectedForBookingCookie", { path: "/me" });
    removeCookieSelectedTime("SelectedTimeCookie", { path: "/me" });
    removeCookieSelectedTime("travelData", { path: "/me" });
    removeCookieSelectedPackage("SelectedPackageCookie", { path: "/me" });

  }, []);

  useEffect(() => {
    //dispatch(getPublicTrainerData(history.location.pathname.split('/')[2]))
    dispatch(changeNavigation(1));

  }, []);

  useEffect(() => {
    if (publicTrainerData.id) {
      dispatch(getPackages(apiInterceptor,publicTrainerData.id));
    }
  }, [publicTrainerData])


  return (
    <div className="packagesAndSubscription initialView">
      <div className="content">
        <div className="header">
          <h1>{publicTrainerData.firstName}s {t("packagesAndSubscriptions_full")}</h1>
          <hr />
        </div>
        <div className="content-row">
          {packages.map((item: any) => (<SubscriptionAndPackageItem item={item} />))}
        </div>
      </div>
    </div>
  );
};
export default PackagesAndSubscription;
