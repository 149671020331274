import React, { useContext, useEffect } from "react";
import "../.././scss/pages/CustomerDashboard.scss";
import CustomerAvatar from "../../components/CustomerDashboard/CustomerAvatar";
import CustomerDetails from "../../components/CustomerDashboard/CustomerDetails";
import SessionsList from "../../components/CustomerDashboard/SessionsList";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCancelledBookings,
  loadCustomerData, loadCustomerPackages,
  loadUpcomingSessions
} from "../../redux/actions/customer/customerAction";
import { changeNavigation } from "../../redux/actions/layout/layoutAction";
import { useCookies } from "react-cookie";
import ApiContext from "../../shared/api-interceptor/api-interceptor.component";
import AuthContext from "../../utility/context/auth-context";
import { useHistory } from "react-router-dom";

const CustomerDashboard = () => {
  const customerData = useSelector(state => state.customer);
  const dispatch = useDispatch();
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  const cancelledBookings = useSelector(state => state.customer.cancelledBookings);

  const [cookiesSelectedTime, setCookieSelectedTime, removeCookieSelectedTime] = useCookies(["SelectedTimeCookie", "travelData"]);
  const [cookiesSelectedPackage, setCookieSelectedPackage, removeCookieSelectedPackage] = useCookies(["SelectedPackageCookie","SelectedPackageForBooking"]);
  const [cookiesSelectedForBooking, setCookieSelectedForBooking, removeCookieSelectedForBooking] = useCookies(["SelectedForBookingCookie"]);
  const authCtx = useContext(AuthContext)
  const history = useHistory();
  useEffect(() => {
    removeCookieSelectedForBooking("SelectedForBookingCookie", { path: "/me" });
    removeCookieSelectedTime("SelectedTimeCookie", { path: "/me" });
    removeCookieSelectedTime("travelData", { path: "/me" });
    removeCookieSelectedPackage("SelectedPackageCookie", { path: "/me" });
    removeCookieSelectedPackage("SelectedPackageForBooking", { path: "/me" });
  }, []);

  useEffect(() => {
    dispatch(loadCustomerData(apiInterceptor,customerData.id));
    dispatch(loadUpcomingSessions(apiInterceptor,customerData.id));
    dispatch(loadCancelledBookings(apiInterceptor,customerData.id));
    dispatch(loadCustomerPackages(apiInterceptor,customerData.id));

    dispatch(changeNavigation(1));
  }, []);

/*  useEffect(() => {
    if(!authCtx.isLoggedIn ){
      history.replace("/");
    }
  }, [localStorage.getItem("token"),authCtx.isLoggedIn]);*/

  return (
    <div className="customer">
      <div className="customer-db">
        <div className="initialView">
          <div className="content">
            <div className="cust-pro">
              <CustomerAvatar />
              <CustomerDetails cancelledBookings={cancelledBookings} />
            </div>
            <SessionsList />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerDashboard;
