/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import imagePlaceholder from "../../../images";
import TrainerSpacePlaceholderImage from "../../../images/personalSpace.png"
import { ReactComponent as BurgerSvg } from "../../../images/component_svg/burgerMenu.svg";

import { getUserData } from "../../../utility/hooks/Utils";

type personalSpaceProps = {
  personalSpaceImage: string;
  setPersonalSpaceImage: any;
  slogan: string;
  setSlogan: any;
  name: string;
  setName: any;
  nickName: string;
  setNickName: any;
  uploadImageFile: any;
  showSpinner: any;
  handleNickName: any;
  handleName: any;
  handleSlogan: any;
  errObject?: any;
  isSubmitButton?: boolean;
};

const PersonalSpace = (props: personalSpaceProps) => {
  const userData = useSelector((state) => state.onboarding.userData);
  const { t } = useTranslation();
  const firstnamePlaceholder = t("firstnamePlaceholder");
  const lastnamePlaceholder = t("lastnamePlaceholder");
  const trainerSince = t("Trainer since 2006");
  const profilePicturePlaceholder = "https://intern-creativemindz.de/wp-content/uploads/user3.svg";



  return (
    <div className="trainerOnboardingEdditYourSpace">
      <div className="card">
        <div className="card_content">
          <div className="card_heading">
            <h2>
              {t("personalSpace")}
            </h2>
            <p>
              {t("Edit your space to differentiate yourself from others.")}
            </p>
            <hr />
          </div>
          <div className="imageUpload">
            <img
              src={props.personalSpaceImage || imagePlaceholder.personalSpace}
              className="personalSpaceImage"
              alt="personalProfile"
            />

            <label htmlFor="uploadButton" className={"uploadButton"}>
              <p>
                {t("change your image")}
              </p>
              <input
                accept=".png,.jpg,.jpeg"
                type="file"
                id={"uploadButton"}
                onChange={props.uploadImageFile}
                placeholder={"upload image"}
              />
            </label>
            <p className={"muted"}>
              {t("headerPictureFormat")}
            </p>
          </div>

          <div className="preview_container">
            <div className="profileNavigationForPreview">
              <div className="navLeft">
                <h1>{`${
                  props.nickName ||
                  `${firstnamePlaceholder} ${lastnamePlaceholder}`
                }`}</h1>
                <ul>
                  <li
                    className={
                      useLocation().pathname ===
                      "/registration-process-edit-your-space"
                        ? "activeLi"
                        : ""
                    }
                  >
                    <Link to={"#"}>
                      {t("About")}
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"}>
                      {t("booking")}
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"}>
                      {t("Packages")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="navRight">
                <div className={"userChipNavigation"}>
                  <p>{`${userData.firstName} ${userData.lastName}`}</p>
                  <img
                    src={userData.profilePictureUri !== "" || null ? `${process.env.REACT_APP_BE_BASEURL}/api/files/${userData.profilePictureUri}/download` : profilePicturePlaceholder}
                    alt=""
                  />
                </div>
              </div>
              <div className="navRightResponsiveForPreview">
                  <BurgerSvg />
              </div>
            </div>
            <div className="personalPreviewImageContainer">
              <img
                src={userData.trainerSpace.logoUrl !== "" || null ? `${process.env.REACT_APP_BE_BASEURL}/api/files/${userData.trainerSpace.logoUrl}/download` : imagePlaceholder.personalSpace}
                className={"personlPerviewImage"}
                alt="personalProfile"
              />
              <div className="text-container">
                <h3>
                  {props.name ||
                    `${firstnamePlaceholder} ${lastnamePlaceholder}`}
                </h3>
                <h6>{props.slogan || trainerSince}</h6>
              </div>
            </div>
          </div>
          <div className="nameAndSlogan">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="nickname">
                  {t("edit nickname")}
                </label>
                <span className={"muted"}>
                  {t("nicknameDescription")}
                </span>
                <input
                  type="text"
                  name="nickname"
                  id="nickname"
                  //   required={true}
                  placeholder={"Max Musterman"}
                  value={props.nickName}
                  onChange={(e) => props.handleNickName(e)}
                />
                {props.errObject?.nickName && !props.nickName && (
                  <div className="validation-error yellow-global mt-2">
                    {props.errObject.nickName}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="name">
                  {t("edit name")}
                </label>
                <span className={"muted"}>
                  {t("editNameDescription")}
                </span>
                <input
                  type="text"
                  name="name"
                  id="name"
                  //   required={true}
                  placeholder={"Max Musterman"}
                  value={props.name}
                  onChange={(e) => props.handleName(e)}
                />
                {props.errObject?.name && !props.name && (
                  <div className="validation-error yellow-global mt-2">
                    {props.errObject.name}
                  </div>
                )}
              </div>
            </div>
            <div className="form-row ">
              <div className="form-group singleFormToRight">
                <label htmlFor="slogan">
                  {t("edit your slogan")}
                </label>
                <span className={"muted"}>
                  {t("editSloganDescription")}
                </span>
                <input
                  type="text"
                  name="slogan"
                  id="slogan"
                  //   required={true}
                  placeholder={"Trainer since 2006"}
                  value={props.slogan}
                  onChange={(e) => props.handleSlogan(e)}
                />
                {props.errObject?.slogan && !props.slogan && (
                  <div className="validation-error yellow-global mt-2">
                    {props.errObject.slogan}
                  </div>
                )}
              </div>
            </div>
            <div className="form-row ">
              <div className="form-group singleFormToRight">
                {props.isSubmitButton && (
                  <button type={"submit"}>
                    {t("save")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalSpace;
