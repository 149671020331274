import Calendar from "react-calendar";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedTime,
  dropSelectedTime,
  getAvailableTimesDuration, getBookingPolicy
} from "../../../redux/actions/booking/bookingAction";
import { DateTime } from "luxon";
import ChooseTimeItem from "./ChooseTimeItem";
import { useTranslation } from "react-i18next";
import { AxiosInstance } from "axios";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";

const BookingCalendar = ({ date, setDate }: { date: Date, setDate: any }) => {

  const availableTimesDuration = useSelector(state => state.booking.availableTimesDuration);
  const spaceUrl = useSelector(state => state.onboarding.publicTrainerData.trainerSpace.spaceUrl);
  const selectedForBooking = useSelector(state => state.businessCenter.services.selectedForBooking);
  const travelData = useSelector(state => state.booking.travelData);
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;

  const dispatch = useDispatch();
  const {
    selectedServiceType,
    selectedDuration
  } = useSelector(state => state.businessCenter.services.selectedForBooking);
  const { t } = useTranslation();
  useEffect(() => {
    if (selectedForBooking?.selectedServiceType === 2) {
      dispatch(getAvailableTimesDuration(apiInterceptor,spaceUrl, DateTime.fromJSDate(date).toUTC().toISO(), selectedServiceType, selectedDuration, travelData?.outwardJourneyDuration, travelData?.returnJourneyDuration));
    } else {
      dispatch(getAvailableTimesDuration(apiInterceptor,spaceUrl, DateTime.fromJSDate(date).toUTC().toISO(), selectedServiceType, selectedDuration));
    }


  }, [date]);

  useEffect(() => {
    setDate(DateTime.now().set({hour:0,minute:0,second:1}).toUTC().toJSDate());
  },[])



  return (
    <div className="bookingCalendar">
      <h5>{t("calendar")}</h5>
      <div className="calendar"
           style={availableTimesDuration.length > 10 ? { flexDirection: "column", alignItems: "center" } : {}}>
        <Calendar onChange={setDate} value={date}
                  minDate={DateTime.now().set({hour:0,minute:0,second:0}).toJSDate()}
        />
        <div className="chooseTime"
             style={availableTimesDuration.length > 10 ? { flexDirection: "row", width: "100%" } : {}}>
          {availableTimesDuration.map((item: { startDate: string, endDate: string }, index: number) => (
            <ChooseTimeItem key={item.startDate} item={item} />
          ))
          }
        </div>
      </div>
    </div>
  );
};

export default BookingCalendar;
