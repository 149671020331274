import { Dispatch } from "react";
import { AxiosInstance } from "axios";

export const setOnboardingStatusAction = (payload: any) => {
  return {
    type: "updateOnboardingStatus",
    payload: payload
  };
};

export const deleteUserDataFromStore = () => {
  return {
    type: "deleteData",
    payload: {
      onboardingStatus: null
    }
  };

};


export const setTrainerData = (apiInterceptor:AxiosInstance) => async (dispatch: Dispatch<any>) => {

  let userData = localStorage.getItem("userData");
  // @ts-ignore
  userData = JSON.parse(userData);
  //TODO: CHECK ts-Ignore
  // @ts-ignore
  if (userData?.userId != null && userData.role === "Trainer") {

    apiInterceptor.get(`/api/trainers/user`).
    then((data) => data.data)
      .then((data) => {

        let formatedData = data.data;
        formatedData.experiences.map((e: any, i: number) => {
          data.data.experiences[i] = { ...data.data.experiences[i], from: e?.periodFrom.substring(0, 10) };
          data.data.experiences[i] = {
            ...data.data.experiences[i],
            to: e?.periodTo === null ? null : e?.periodTo.substring(0, 10)
          };
          //return formatedData;
        });
        return formatedData;
      })
      .then((data) => {
        dispatch({
          type: "setTrainerData",
          payload: data
        });
        localStorage.setItem("trainerId", data.id);
        localStorage.setItem("pictureId", data.profilePictureUri);
      }).catch((err) => {
    });
  }
};
export const getPublicTrainerData = (apiInterceptor:AxiosInstance,spaceUrl: string, fromProfile?: boolean) => async (dispatch: Dispatch<any>) => {


  if (spaceUrl && spaceUrl !== "trainer") {

    apiInterceptor.get(`/api/trainers/${spaceUrl}/public`).then(data => data.data)
      .then((data) => {

        if (!data.data && fromProfile) {
          dispatch({
            type: "setTrainerId",
            payload: null
          });
        }

        let formatedData = data.data;
        formatedData.experiences.map((e: any, i: number) => {
          data.data.experiences[i] = { ...data.data.experiences[i], from: e.periodFrom.substring(0, 10) };
          data.data.experiences[i] = { ...data.data.experiences[i], to: e.periodTo.substring(0, 10) };
        });
        return formatedData;
      })
      .then((data) => {
        dispatch({
          type: "getPublicTrainerData",
          payload: data
        });
        localStorage.setItem("trainerId", data.id);
      }).catch((err) => {
      if (spaceUrl !== "trainer" && spaceUrl!=="undefined" ) {
        window.location.replace("/trainerNotFound");
      }
    });
  }
};

