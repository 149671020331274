import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as PersonalSVG } from "../../../images/component_svg/personalTraining.svg";
import { ReactComponent as SmallGroupSVG } from "../../../images/component_svg/smallGroup.svg";
import { ReactComponent as VirtualSVG } from "../../../images/component_svg/virtual.svg";
import { ReactComponent as OnSiteCoachSVG } from "../../../images/component_svg/onSiteCoach.svg";
import { ReactComponent as OnSiteClientSVG } from "../../../images/component_svg/onSiteClient.svg";
import { ReactComponent as DificultyLevelSVG } from "../../../images/component_svg/difficultyLevel.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectedForBookingAction } from "../../../redux/actions/businessCenter/serviceActions";
import { DateTime } from "luxon";
import { difficultyLevelEnum } from "../../../enums/enum";
import { dropSelectedTime } from "../../../redux/actions/booking/bookingAction";
import { useTranslation } from "react-i18next";
import ModalContext from "../../../utility/context/modal-context";
import { bookingDataType } from "../../../redux/reducers/booking/bookingReducer";
import AuthContext from "../../../utility/context/auth-context";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import i18next from "i18next";
import { ReactComponent as LocationSVG } from "../../../images/component_svg/locationSVG.svg";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";


const BookingSmallGroupCalendar = ({
                                     serviceArray,
                                     getDisciplinesStringFromArray,
                                     updateFilter
                                   }: any) => {

  const { i18n, t } = useTranslation();
  const smallGroupSessionList = useSelector(
    (state) => state.booking.availableSmallGroupSessions
  );
  const selectedService = useSelector(
    (state) => state.businessCenter.services.selectedForBooking);
  const selectedPackageItem = useSelector(
    (state) => state.customer.selectedPackageItem);
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);

  const selectingFromCancellation: boolean = useSelector(state => state.booking.selectingFromCancellation);
  const selectedCreditItem = useSelector(state => state.customer.selectedCreditItem);
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;

  const selectedTime = useSelector((state) => state.booking.selectedTime);
  const [currentParticipants, setCurrentParticipants] = useState<number>(0);
  const [showPopup, setShowPopup] = useState(false);
  const [calendarDays, setCalendarDays] = useState(7);

  const modalCtx = useContext<any>(ModalContext);

  useEffect(() => {
    createAvailableSessions();
  }, [smallGroupSessionList]);

  useEffect(() => {
    getCurrentParticipants();
    return () => {
    };
  }, [selectedService]);


  const handleResize = () => {
    if (window.innerWidth < 800) {
      setCalendarDays(1);
    } else if (window.innerWidth < 950) {
      setCalendarDays(2);
    } else if (window.innerWidth < 1100) {
      setCalendarDays(3);
    } else if (window.innerWidth < 1300) {
      setCalendarDays(4);
    }
    else {
      setCalendarDays(7);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);


  const createAvailableSessions = () => {

    let sessionArray: Array<any> = [];
    if (smallGroupSessionList != null && smallGroupSessionList.length > 0 && smallGroupSessionList.length !== 0) {
      smallGroupSessionList.forEach((service: any, index: number) => {

        let start = DateTime.fromISO(
          `${service.startDate?.substring(0, 10)}T07:00:00`
        );
        let end = start.plus({ minute: service.durationInMinutes });


        const weekday = new Date(
          service.startDate
        ).getDay();
        sessionArray.push({
          overlap: false,
          start: start.toISO(),
          end: end.toISO(),
          realStart: DateTime.fromISO(service.startDate).toISO(),
          realEnd: DateTime.fromISO(service.endDate).toISO(),
          id: service.service.id,
          title: service.service.name,
          startDate: DateTime.fromISO(service.startDate).toISODate(),
          selectedServiceType: service.service.serviceTypes[0],
          category: 1,
          durationSession: service.service.durationInMinutes[0],
          dayOfWeek: weekday,
          selectedDate: DateTime.fromISO(service.startDate).toISODate(),
          parsedDate: DateTime.fromISO(service.startDate).toFormat("dd.MM.yyyy"),
          selectedDuration: service.service.durationInMinutes[0],
          maximumParticipants: service.service.maximumParticipants,
          service: {
            ...service.service,
            bookings: []
          },
          setShowPopup: setShowPopup
        });
      });
      setAvailableSessions(sessionArray);
    }


  };
  const getCurrentParticipants = async () => {
    const url = `/api/bookings/small-group-participants/${selectedService.id}/${selectedService.selectedDate}`;

    if (selectedService.id != -1) {
      try {
        const response = await apiInterceptor.get(url);

        if (response.status === 200) {
          setCurrentParticipants(response.data.participantsCount);
        }
      } catch (error) {
      }
    }
  };
  const setCustomerOnWaitingList = async (e: any) => {
    e.preventDefault();
    const url = `/api/bookings/multiple?forWaitingList=true`;


    let data: bookingDataType = { promotionCodeCode: "", "packageId": null, serviceBookingRequests: [] };

    if (selectedPackageItem.packageId !== -1) {
      data = { ...data, packageId: selectedPackageItem.packageId };
    }

    let serviceAmount: number = 0;

    if (selectedService.category === 1) {
      serviceAmount = selectedService.groupPrice;
    } else if (selectedService.selectedServiceType === 0) {
      serviceAmount = selectedService.virtualFee;
    } else {
      serviceAmount = selectedService.onsiteFee;
    }

    let serviceFee = serviceAmount * publicTrainerData.feeFactor;

    data.serviceBookingRequests.push({
      serviceId: selectedService.id,
      serviceType: selectedService.selectedServiceType,
      disciplines: selectedService.disciplines,
      durationInMinutes: selectedService.selectedDuration,
      startTime: DateTime.fromISO(`${selectedService.selectedDate}T${selectedService.startTime}`).toUTC().toISO(),
      endTime: DateTime.fromISO(`${selectedService.selectedDate}T${selectedService.startTime}`).plus({ minutes: selectedService.selectedDuration }).toUTC().toISO(),
      serviceCategory: selectedService.category,
      serviceAmount: serviceAmount,
      serviceFee: serviceFee,
      travelExpense: 0,
      totalPrice: serviceFee + serviceAmount
    });


    if (selectedTime[0].packageId !== -1) {
      data = { ...data, packageId: selectedTime[0].packageId };
    }
    try {
      const response = await apiInterceptor.post(url,data);

      if (response.status === 200) {
        modalCtx.setModalType(0);
        modalCtx.setMessage(t("Successful"));
        modalCtx.setIsActive(true);

      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(response.data.message);
        modalCtx.setIsActive(true);

      }


    } catch (e) {
    }

  };

  const [availableSessions, setAvailableSessions] = useState<Array<any>>();

  return (
    <div className="smallGroupCalendar">

      <div className="calendar">
        <FullCalendar

          plugins={[interactionPlugin, timeGridPlugin, dayGridPlugin]}
          initialView="customGrid"
          events={availableSessions}
          eventContent={(args) => <EventItem args={args} />}
          slotMinTime={"07:00:00"}
          slotMaxTime={"21:00:00"}
          slotLabelContent={null}
          titleRangeSeparator={" - "}
          allDaySlot={false}
          selectable={false}
          locale={i18next.language}
          eventDisplay={"block"}
          dayHeaderFormat={{ weekday: "long", omitCommas: false }}
          firstDay={1}
          eventOrderStrict={true}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
            hour12: true
          }}
          headerToolbar={{
            left: "",
            center: "prev,title,next today",
            right: ""
          }}
          views={{
            customGrid: {
              type: "dayGridWeek",
              duration: { days: calendarDays },
              slotMinTime: "07:00:00",
              slotMaxTime: "20:00:00",
              weekends: true
            }
          }}

        />
      </div>
      <div className="bottom-row">
        <div className="selectedService"
             style={showPopup ? {} : { opacity: 0 }}
        >
          <div
            className={`selectedServiceCard ${
              selectedService.serviceTypes[0] === 0
                ? "selectedServiceCardVirtual"
                : "selectedServiceCardOnSite"
            }`}
            style={selectedService.id === -1 ? { opacity: 0 } : {}}
          >
            <div className="text-container">
              <h2>{selectedService.name}</h2>
              <p>
                {selectedService.parsedDate} | {selectedService.startTime && DateTime.fromFormat(selectedService.startTime, "HH:mm:ss").toFormat("HH:mm")} |{" "}
                {selectedService.durationInMinutes}
                {t("minutes")}
              </p>
              <p>
                {currentParticipants}/{selectedService.maximumParticipants}{" "}
                {t("participants")}
              </p>
              <p>{selectedService.description}</p>

              {selectedService.selectedServiceType === 1 && publicTrainerData ?
              (
              <div className="selectedServiceCustomerAddress">
                 <LocationSVG
                    fill="232323"
                  />
                  <p onClick={() => window.open(
                    `https://www.google.com/maps/place/${publicTrainerData.trainerWorkoutAddress}+${publicTrainerData.trainerWorkoutHouseNumber}+${publicTrainerData.trainerWorkoutPostalCode}+${publicTrainerData.trainerWorkoutCity}`, "_blank")}>
                    {publicTrainerData.trainerWorkoutAddress}
                    {" "}
                    {publicTrainerData.trainerWorkoutHouseNumber}
                    {", "}
                    {publicTrainerData.trainerWorkoutPostalCode}
                    {" "}
                    {publicTrainerData.trainerWorkoutCity}
                  </p>
                </div>) : null}
            </div>
            <div className="icon-container">
              {selectedService.serviceTypes[0] === 0 ? (
                <div className={"icon-text"}>
                  <VirtualSVG />
                  <p>
                    {t("virtual")}
                  </p>
                </div>
              ) : null}
              {selectedService.serviceTypes[0] === 1 ? (
                <div className={"icon-text"}>
                  <OnSiteCoachSVG />
                  <p style={{ color: "#232323" }}>
                    {t("on site coach")}
                  </p>
                </div>
              ) : null}
              <div className="icon-text">
                <DificultyLevelSVG />
                <p>{difficultyLevelEnum[selectedService.difficultyLevel]}</p>
              </div>
              {
                selectedService.maximumParticipants == currentParticipants ? (
                  <button
                    onClick={setCustomerOnWaitingList}
                    className={selectedService.serviceTypes[0] === 0 ? "virtualWaitingListButton" : "privateWaitingListButton"}>{t("waitinglist")}
                  </button>
                ) : null
              }

            </div>

          </div>
        </div>
        <button disabled={selectedTime.length <= 0}>next</button>
      </div>
    </div>
  );
};

const EventItem = ({ args }: any) => {

  const [currentParticipants, setCurrentParticipants] = useState<number>(0);
  const [isInPast, setIsInPast] = useState<boolean>(DateTime.now() >= DateTime.fromISO(args.event.extendedProps.realStart));
  const { i18n } = useTranslation();
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  useEffect(() => {
    getCurrentParticipants();
  }, []);

  const getCurrentParticipants = async () => {
    const url = `/api/bookings/small-group-participants/${args.event.extendedProps.service.id}/${args.event.extendedProps.startDate}`;

    try {
      const response = await apiInterceptor.get(url);

      if (response.status === 200) {
        setCurrentParticipants(response.data.participantsCount);
      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(response.statusText);
        modalCtx.setIsActive(true);
      }
    } catch (error) {
    }
  };

  const publicTrainerData = useSelector(
    (state) => state.onboarding.publicTrainerData);
  const [serviceFee, setServiceFee] = useState<number>(0);
  const available =
    useSelector(
      (state) =>
        state.booking.selectedTime.filter(
          (s: any) => s.startDate === DateTime.fromISO(args.event.extendedProps.realStart).toUTC().toISO()
        ).length
    ) > 0;

  const selectingFromCancellation: boolean = useSelector(state => state.booking.selectingFromCancellation);
  const selectedCreditItem = useSelector(state => state.customer.selectedCreditItem);


  const selectedTime = useSelector(
    (state) => state.booking.selectedTime);
  const selectedPackageItem = useSelector(
    (state) => state.customer.selectedPackageItem);
  const extendedProps = args.event.extendedProps;

  const modalCtx = useContext<any>(ModalContext);

  const dispatch = useDispatch();
  const handleSelectService = () => {
    if (publicTrainerData.feeType === 0 || publicTrainerData.feeType === 1) {
      setServiceFee(0);
    } else if (publicTrainerData.feeType === 2) {
      setServiceFee((extendedProps.service.groupPrice) * publicTrainerData.feeFactor);

    } else if (publicTrainerData.feeType === 2) {
      if (selectedTime[0].packageId !== -1) {
        setServiceFee((0) * (publicTrainerData.feeFactor / 2));
      } else {
        setServiceFee((extendedProps.service.groupPrice) * (publicTrainerData.feeFactor / 2));
      }
    }

    dispatch(
      selectedForBookingAction([
        {
          ...extendedProps.service,
          dayOfWeek: extendedProps.dayOfWeek,
          selectedDate: extendedProps.selectedDate,
          selectedServiceType: extendedProps.selectedServiceType,
          selectedDuration: extendedProps.selectedDuration,
          parsedDate: extendedProps.parsedDate
        }
      ])
    );

    if (!available) {
      if (selectedPackageItem?.count > selectedTime?.length && selectedPackageItem.id !== -1) {
        dispatch({
          type: "addSelectedTime",
          payload: {
            /*     startDate: `${extendedProps.selectedDate}T${extendedProps.service.startTime}`,
                 endDate: `${extendedProps.selectedDate}T${DateTime.fromISO(
                   `${extendedProps.selectedDate}T${extendedProps.service.startTime}`
                 )
                   .plus({ minute: extendedProps.selectedDuration })
                   .toISO()
                   .substring(11, 19)}`,*/
            startDate: DateTime.fromISO(extendedProps.realStart).toUTC().toISO(),
            endDate: DateTime.fromISO(extendedProps.realEnd).toUTC().toISO(),
            totalPrice: 0,
            serviceFee: 0,
            servicePrice: 0,
            serviceName: extendedProps.service.name,
            service: extendedProps.service,
            packageId: selectedPackageItem.id,
            maximumParticipants: args.event.extendedProps.maximumParticipants,
            currentParticipants: currentParticipants
          }
        });
      } else if (selectedPackageItem?.count <= selectedTime?.length && selectedPackageItem.id) {
        modalCtx.setModalType(0);
        modalCtx.setMessage(t("You can't select more items then remaining counts"));
        modalCtx.setIsActive(true);
      } else if (selectingFromCancellation && selectedTime?.length >= 1) {
        modalCtx.setMessage(t("Rebook only allows one session"));
        modalCtx.setIsActive(true);
      } else {
        dispatch({
          type: "addSelectedTime",
          payload: {
            //startDate: extendedProps.realStart, //`${extendedProps.selectedDate}T${extendedProps.service.startTime}`,
            /* endDate:  extendedProps.realEnd,`${extendedProps.selectedDate}T${DateTime.fromISO(
            `${extendedProps.selectedDate}T${extendedProps.service.startTime}`
          )
              .plus({ minute: extendedProps.selectedDuration })
              .toISO()
              .substring(11, 19)}`,*/
            startDate: DateTime.fromISO(extendedProps.realStart).toUTC().toISO(),
            endDate: DateTime.fromISO(extendedProps.realEnd).toUTC().toISO(),
            totalPrice: extendedProps.service.groupPrice + serviceFee,
            serviceName: extendedProps.service.name,
            serviceFee: serviceFee,
            servicePrice: extendedProps.service.groupPrice,
            service: extendedProps.service,
            packageId: -1,
            maximumParticipants: args.event.extendedProps.maximumParticipants,
            currentParticipants: currentParticipants
          }
        });
        extendedProps.setShowPopup(true);
      }
    } else {
      dispatch(
        dropSelectedTime(
          DateTime.fromISO(extendedProps.realStart).toUTC().toISO()
        )
      );
      extendedProps.setShowPopup(false);

    }

  };
  const { t } = useTranslation();
  return (
    <div className={"event"}>
      <input type={"checkbox"} id={"smallGroupItem"} checked={available} />
      <label
        htmlFor={"smallGroupItem"}
        onClick={isInPast ? () => {
        } : handleSelectService}
        className={"eventItem"}
        style={isInPast ? { opacity: "0.3" } : {}}
      >
        <div
          className={`content-container ${
            available
              ? extendedProps?.selectedServiceType === 0
                ? "aktiveVirtual"
                : "activeOnsite"
              : ""
          } ${
            extendedProps?.selectedServiceType === 0
              ? "virtualBorder"
              : "onSiteBorder"
          }`}
        >
          <div className="text-container">
            <h1>{args.event.title}</h1>
            <p className="date">
              {DateTime.fromFormat(extendedProps.service.startTime, "HH:mm:ss").toFormat("HH:mm")} -{" "}
              {DateTime.fromFormat(extendedProps.service.startTime, "HH:mm:ss").plus({ minute: extendedProps.selectedDuration }).toFormat("HH:mm")}
            </p>
            {args.event.extendedProps.category === 1 ? (
              <div
                className="smallGroupParticipants">{currentParticipants}/{args.event.extendedProps.maximumParticipants}</div>
            ) : null}
            <p>
              {args.event.extendedProps.selectedServiceType === 0 ? (
                t("virtual")
              ) : null}
            </p>
            <p>
              {args.event.extendedProps.selectedServiceType === 1 ? (
                t("on site coach")
              ) : null}
            </p>
            <p>
              {args.event.extendedProps.selectedServiceType === 2 ? (
                t("onSiteClient")
              ) : null}
            </p>
            <p>
              {args.event.extendedProps.durationSession}{" "}
              {t("minutes")}
            </p>
          </div>
          <div className="icon-container">
            <div className="category">
              {args.event.extendedProps.category === 0 ? <PersonalSVG /> : null}
              {args.event.extendedProps.category ? (
                <SmallGroupSVG
                  style={
                    args.event.extendedProps.selectedServiceType != 0
                      ? { stroke: "white" }
                      : { stroke: "white" }
                  }
                />
              ) : null}
            </div>
            <div className="serviceType">
              {args.event.extendedProps.selectedServiceType === 0 ? (
                <VirtualSVG />
              ) : null}
              {args.event.extendedProps.selectedServiceType === 1 ? (
                <OnSiteCoachSVG />
              ) : null}
              {args.event.extendedProps.selectedServiceType === 2 ? (
                <OnSiteClientSVG />
              ) : null}
            </div>
          </div>
        </div>

      </label>
    </div>
  );
};

export default BookingSmallGroupCalendar;

