import AuthContext from '../../utility/context/auth-context';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SignUpFormsAction} from '../../redux/actions/registration/SignUpFormsAction';
import TrainerOnboardingTop from '../onboarding/TrainerOnboarding/TrainerOnboardingTop';
import {setTrainerData} from '../../redux/actions/registration/OnboardingAction';
import {getUserData} from '../../utility/hooks/Utils';
import {Button} from "reactstrap";
import { useTranslation } from 'react-i18next';
import { ReactComponent as BurgerSvg } from "../../images/component_svg/burgerMenu.svg"
import ApiContext from "../../shared/api-interceptor/api-interceptor.component";

const ProfileNavigationForPreview = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.onboarding.userData);
    const spacePreview = useSelector((state) => state.spacePreview);
    const { t } = useTranslation();
    const apiContext = useContext(ApiContext);
    const {apiInterceptor} = apiContext;
    const pathname = useLocation().pathname;
    useEffect(() => {
        dispatch(setTrainerData(apiInterceptor))
    }, [])

    return (
        <div className="profileNavigationForPreview">
            <div className="navLeft">
                <h1
                >{spacePreview?.nickname != null ? `${spacePreview.nickname}` : `${userData.firstName} ${userData.lastName}`}</h1>
                <ul>
                    <li
                        className={'activeLi'}>
                        <Link to={`#`}>{t("About")}</Link>
                    </li>
                    <li>
                        <Link to={`#`}>{t("booking")}</Link>
                    </li>
                    <li>
                        <Link to={'#'}>{t("Packages")}</Link>
                    </li>
                </ul>
            </div>
            <div className="navRight">
                <button onClick={history.goBack}>Login</button>
            </div>
            <div className="navRightResponsiveForPreview">
                  <BurgerSvg />
            </div>
        </div>
    );
};

export default ProfileNavigationForPreview;
