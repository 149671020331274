import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOnboardingStatusAction,
  setTrainerData
} from "../../redux/actions/registration/OnboardingAction";
import { useHistory } from "react-router-dom";
import TrainerOnboardingBottomBar from "../../components/onboarding/TrainerOnboarding/TrainerOnboardingBottomBar";
import AuthContext from "../../utility/context/auth-context";
import ModalContext from "../../utility/context/modal-context";
import { useTranslation } from "react-i18next";
import ApiContext from "../../shared/api-interceptor/api-interceptor.component";


const TrainerOnboardingKYC = () => {
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  const dispatch = useDispatch();
  const userData = useSelector(state => state.onboarding.userData);
  const [done, setDone] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<any>("");

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext<any>(ModalContext);
  const { i18n, t } = useTranslation();


  useEffect(() => {
    dispatch(setTrainerData(apiInterceptor));
    dispatch(setOnboardingStatusAction(4));
  }, []);

  useEffect(() => {
    getKYCStatus();
    const intervalId = setInterval(getKYCStatus, 10000);
    setIntervalId(intervalId);

    return () => {
      clearInterval(intervalId);
    };

  }, []);


  const getKYCStatus = async () => {
    if (userData.id !== null && !done) {
      const url = `/api/trainers/kyc-status`;

      try {
        const response = await apiInterceptor.get(url);
        if (response.status === 200) {
          setDone(response.data);
        }else{
          setDone(false);
        }

      } catch (error) {
        setDone(false);

      }
    } else {
      if (intervalId !== "")
        clearInterval(intervalId);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (process.env.REACT_APP_IS_TESTPHASE !== "TRUE") {

      if (done && userData.id !== (-1 || null)) {
        clearInterval(intervalId);
        const url = `/api/trainers/user`;

        const body = {
          ...userData,
          onboardingStatus: 5
        };

        const response = await apiInterceptor.put(url,body);
        if (response.status === 200) {
          history.replace(`/me/${userData.trainerSpace.spaceUrl}/trainer-dashboard`);
        } else {
          modalCtx.setModalType(0);
          modalCtx.setMessage(response.data.message);
          modalCtx.setIsActive(true);
        }
      }else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(t("before you can get started with SKULP, we ask you to, set up your bank account"));
        modalCtx.setIsActive(true);
      }
    }

  };

  const startKYC = async (e: any) => {
    e.preventDefault();
    const url = `/api/trainers/kyc`;

    try {

      const response = await apiInterceptor.post(url);


      if (response.status ===200) {
        window.location.href = response.data.message;
      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(response.data.message);
        modalCtx.setIsActive(true);
      }

    } catch (error) {
    }
  };


  const history = useHistory();

  const handlePreviousStep = () => {
    history.replace("/registration-process-edit-your-space");
  };


  return (
    <div className="trainerOnboardingKYC">

      <div className={"initialView"}>
        <div className="content">
          <div className="pageTitle">
            <h1>{t("set up your bank account")}</h1>
            <hr />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="card" style={{ width: "50%", padding: "2rem" }}>
              {!done ?
                <div className="card_content">
                  <h3>Hey {userData.firstName},</h3>
                  <p>
                    {t("before you can get started with SKULP, we ask you to, set up your bank account")}
                    {t("this is where our verified partner stripe supports us")}
                  </p>

                  <button onClick={startKYC}>{t("start setup")}</button>
                  <div className={"kycDone"}>
                  </div>
                  <TrainerOnboardingBottomBar
                    handlePreviousStep={handlePreviousStep}
                  />
                </div>
                :
                /*             <div className="card_content">
                               <p>{t("great, almost there")}</p>
                               <p>{t("stripe will now send you a confirmation email. Please have the following things ready")}</p>
                               <ul>
                                 <li>{t("identity card, passport or driver's license")}</li>
                                 <li>{t("last utility bill")}</li>
                                 <li>{t("business registration or excerpt from the commercial register")}</li>
                               </ul>

                             </div>*/
                <div className="card_content">
                  <p>{t("you have successfully completed onboarding")}</p>
                  <p>{t("now there are only two small things to do")}</p>
                  <p>{t("create your first service")}</p>
                  <p>{t("enter your calendar availabilities")}</p>
                  <p>{t("please note: Only after you have completed these two steps, all functions will be unlocked.")}</p>
                </div>
              }
            </div>
            <TrainerOnboardingBottomBar
              handlePreviousStep={handlePreviousStep}
            />
          </form>

        </div>
      </div>
    </div>

  );
};

export default TrainerOnboardingKYC;
{/*        <div className="status">
                  <h6>KYC Status</h6>
                  <div className="kyc-status" style={done ? { background: "green" } : {}} />
                  {done ? <p className="muted">completed</p> : <p className="muted">incomplete</p>}
                </div>*/
}
