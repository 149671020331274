import React, { useContext } from "react";
import mailSVG from '../../../images/component_svg/mail.svg'
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";


const ResendPasswordInstructions = () => {
  const { i18n , t} = useTranslation();
    const apiContext = useContext(ApiContext);
    const {apiInterceptor} = apiContext;

  const userdata = useSelector(state => state.registration)
  const dispatch = useDispatch();
    const handleResendActivationEmail = async () => {

      const response = await apiInterceptor.get(`/api/User/forgotPassword/resend-email/${userdata.email.replace("@","%40")}`)

    }

    return (
        <div className="resendInstructions">
            <h1>{t("instructionSent")}</h1>
            <img src={mailSVG} alt=""/>
            <p>{t("instructionsHowToResetPassword")}</p>
            <p>{userdata.email}</p>
            <hr/>
            <button type={"submit"} onClick={handleResendActivationEmail}>
                {t("resendInstruction")}
            </button>

        </div>

    )

}

export default ResendPasswordInstructions
