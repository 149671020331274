import { Dispatch } from "react";
import { AxiosInstance } from "axios";


export const loadCustomerData = (apiInterceptor:AxiosInstance,customerID  : number = -1) => async (dispatch: Dispatch<any>) => {

    const url = `/api/customers/user`;

    const response = await apiInterceptor.get(url);

    if (response.status === 200) {
      const res = response.data;

      dispatch({
        type: "setCustomerObject",
        payload: { ...res.data }
      });
    }
};


export const loadCustomerPackages = (apiInterceptor:AxiosInstance,customerId?: number) => async (dispatch: Dispatch<any>) => {

  if (customerId !== -1) {
    const url = `/api/packages/customer`;

    try {
      const response = await apiInterceptor.get(url);

      if (response.status === 200) {
        const res = response.data
        let resData = res.data;
        dispatch({
          type: "loadCustomerPackages",
          payload: (resData)
        });
      }
    } catch (error) {
    }
  }
};

export const loadUpcomingSessions = (apiInterceptor:AxiosInstance,userId: number) => async (dispatch: Dispatch<any>) => {

  if (userId !== -1) {
    const url = `/api/bookings/upcoming?customerId=${userId}`;

    try {
      const response = await apiInterceptor.get(url);

      if (response.status === 200) {
        const res = response.data;
        dispatch({
          type: "loadUpcomingSessions",
          payload: res
        });
      }
    } catch (error) {
    }
  }
};

export const loadCancelledBookings = (apiInterceptor:AxiosInstance,userId: number) => async (dispatch: Dispatch<any>) => {


  if (userId !== -1) {
    const url = `/api/bookings/cancelled-bookings`;

    try {
      const response = await apiInterceptor.get(url);

      if (response.status === 200) {
        const res = response.data;
        dispatch({
          type: "loadCancelledBookings",
          payload: res
        });
      }
    } catch (error) {
    }
  }
};


export const setSelectedPackageItemForBooking =  (payload : any) => {
  return {
    type: "setSelectedPackageItem",
    payload: payload
  }
}
export const setSelectedCreditItemForBooking =  (payload : any) => {
  return {
    type: "setSelectedCreditItem",
    payload: payload
  }
}

export const eraseSelectedPackageItemForBooking = () => {
  const payload = {
    id: -1,
    category: -1,
    name: "",
    description: "",
    serviceTypes:[],
    disciplines: [],
    durationInMinutes: [],
    onsiteFee: -1,
    virtualFee: -1,
    enabled: false,
    bookings: [],
    groupPrice: -1,
    date: "",
    startTime: "",
    maximumParticipants: -1,
    maximumParticipantsInWaitingList: -1,
    difficultyLevel: -1,
    dayOfWeeks: [],
    weekRepeats: -1,
    location: "",
    address: "",
    postalCode: -1,
    city: "",
    country: -1,
    houseNumber: ""
  }
  return {
    type: "setSelectedPackageItem",
    payload: payload
  }
}
