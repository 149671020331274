import Calendar from "react-calendar";
// @ts-ignore
import FullCalendar from "@fullcalendar/react"; // must go before plugins
// @ts-ignore
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
// @ts-ignore
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
// @ts-ignore
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { ReactComponent as SmallGroupSVG } from "../../../images/component_svg/smallGroup.svg";
import { ReactComponent as PersonalSVG } from "../../../images/component_svg/personalTraining.svg";
import { ReactComponent as VirtualSVG } from "../../../images/component_svg/virtual.svg";
import { ReactComponent as OnSiteClientSVG } from "../../../images/component_svg/onSiteClient.svg";
import { ReactComponent as OnSiteCoachSVG } from "../../../images/component_svg/onSiteCoach.svg";
import { ReactComponent as DownArrowSVG } from "../../../images/component_svg/downArrowInputTime.svg";
import { ReactComponent as LocationSVG } from "../../../images/component_svg/locationSVG.svg";
import { ReactComponent as ReloadSvg } from "../../../images/component_svg/reload.svg";

import AuthContext from "../../../utility/context/auth-context";
import ModalContext from "../../../utility/context/modal-context";

import {
  cancelBooking,
  cancelGroupSession, getCalendar,
  setSelectedCalendarItem,
  setWeeklyView
} from "../../../redux/actions/businessCenter/calendarAction";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DificultyLevelSVG } from "../../../images/component_svg/difficultyLevel.svg";
import { difficultyLevelEnum } from "../../../enums/enum";
import { useTranslation } from "react-i18next";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";


export type selectedCalendarItemType = {
  id: number;
  category: number;
  serviceType: number;
  name: string;
  startDate: string;
  endDate: string;
  duration: number;
  maximumParticipants: number;
  ParticipantsCount: number;
  description: string;
  difficultyLevel: number;
  bookingId?: number;
  isRecurring?: boolean,
  serviceId?: number,
  customerAddress?: object,
  customer?: any
};

const EventItem = ({ args }: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [numberOfParticipants, setNumberOfParticipans] = useState(0);
  const authCtx = useContext(AuthContext);
  const { i18n } = useTranslation();
  const [isInPast, setIsInPast] = useState<boolean>(DateTime.now() >= DateTime.fromISO(args.event.extendedProps.startDate));
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  useEffect(() => {
    getNumberParticipants();
  }, []);

  const getNumberParticipants = async () => {
    if (args.event.extendedProps.serviceId >= 0) {
      const url = `/api/bookings/small-group-participants/${args.event.extendedProps.serviceId}/${DateTime.fromISO(args.event.extendedProps.startDate).toUTC()}`;
      try {
        const response = await apiContext.apiInterceptor.get(url);
        if (response.status === 200) {
          setNumberOfParticipans(response.data.participantsCount);
        }
      } catch (e) {
      }
    }
  };

  const handleSelectedCalendarItem = (valid: boolean) => {
    const customerAddress = args.event.extendedProps.booking && args.event.extendedProps.booking.customerAddress ? args.event.extendedProps.booking.customerAddress : null;
    if (valid) {
      const data: selectedCalendarItemType = {
        id: args.event.id,
        category: args.event.extendedProps.category,
        serviceType: args.event.extendedProps.serviceType,
        duration: args.event.extendedProps.durationSession,
        name: args.event.title,
        startDate: args.event.extendedProps.startDate,
        endDate: args.event.extendedProps.endDate,
        maximumParticipants: args.event.extendedProps.maximumParticipants,
        ParticipantsCount: 5,
        customer: args.event.extendedProps.customer,
        description: args.event.extendedProps.description,
        difficultyLevel: args.event.extendedProps.difficultyLevel,
        bookingId: args.event.extendedProps.bookingId,
        isRecurring: args.event.extendedProps.isRecurring,
        serviceId: args.event.extendedProps.serviceId,
        customerAddress: customerAddress
      };

      dispatch(setSelectedCalendarItem(data));
    }
  };

  const duration: number = args.event.extendedProps.durationSession;

  let cardClass: string = "";
  if (duration <= 40) {
    cardClass = "card-less-40";
  }
  if (duration <= 50 && duration > 40) {
    cardClass = "card-less-50";
  }

  if (duration > 80) {
    cardClass = "card-gt-80";
  }

  return (
    <div className="event"
         style={isInPast ? { height: "100%", opacity: 0.3, cursor: "not-allowed" } : { height: "100%" }}>
      <div
        className="eventItem"
        style={
          args.event.extendedProps.serviceType !== -1
            ? args.event.extendedProps.serviceType !== 0
              ? {
                backgroundColor: "#FDFC00",
                color: "#232323",
                fill: "black"
              }
              : { backgroundColor: "#FF045D", color: "white", fill: "white" }
            : {
              background: "rgba(255,255,255,.2)",
              color: "white",
              fill: "white"
            }
        }
        onClick={() =>
          isInPast ? null : handleSelectedCalendarItem(args.event.extendedProps.serviceType != -1)
        }
      >
        <div className={`content-container ${cardClass}`}>
          <div className="text-container">
            <h1>{args.event.title}</h1>
            <p className="date">{args.timeText}</p>

            {/* other states */}
            {args.event.extendedProps.category === 1 ? (
              <div
                className="smallGroupParticipants">{numberOfParticipants}/{args.event.extendedProps.maximumParticipants}</div>
            ) : null}
            <p>
              {args.event.extendedProps.serviceType === 0 && duration > 40 ? (
                t("virtual")
              ) : null}
            </p>
            <p>
              {args.event.extendedProps.serviceType === 1 && duration > 70 ? (
                t("on site coach")
              ) : null}
            </p>
            <p>
              {args.event.extendedProps.serviceType === 2 && duration > 50 ? (
                t("onSiteClient")
              ) : null}
            </p>
            {args.event.extendedProps.serviceType !== -1 && duration > 50 ? (
              <p>
                {args.event.extendedProps.durationSession}{" "}
                {t("minutes")}
              </p>
            ) : null}
            {duration <= 50 ? <p>...</p> : null}
          </div>
          <div className="icon-container">
            <div className="category">
              {args.event.extendedProps.category === 0 ? <PersonalSVG /> : null}
              {args.event.extendedProps.category === 1 ? (
                <SmallGroupSVG
                  style={
                    args.event.extendedProps.serviceType != 0
                      ? { stroke: "black" }
                      : { stroke: "white" }
                  }
                />
              ) : null}
            </div>
            <div className="serviceType">
              {args.event.extendedProps.serviceType === 0 ? <VirtualSVG /> : null}
              {args.event.extendedProps.serviceType === 1 ? (
                <OnSiteCoachSVG />
              ) : null}
              {args.event.extendedProps.serviceType === 2 ? (
                <OnSiteClientSVG />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// End OF EVENT ITEM

// Start OF WEEKLY VIEW

const WeeklyView = () => {
  const dispatch = useDispatch<any>();
  const [calendarData, setCalendarData] = useState<Array<any>>();
  const [numberOfParticipants, setNumberOfParticipans] = useState(0);
  const isLoading = useSelector(state => state.layout.isLoading);
  const authCtx = useContext(AuthContext);
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;

  const weeklyViewData = useSelector(
    (state) => state.businessCenter.calendar.weeklyView
  );
  const [participantsArray, setParticipantsArray] = useState<Array<any>>([]);

  const selectedCalendarItem = useSelector(
    (state) => state.businessCenter.selectedCalendarItem
  );

  const userData = useSelector(
    (state) => state.onboarding.userData
  );

  const { i18n } = useTranslation();

  useEffect(() => {
    getParticipants();
    getNumberParticipants();
  }, [selectedCalendarItem]);

  const getParticipants = async () => {
    const url = `/api/calendar/small-group-participants-detail/${selectedCalendarItem.serviceId}/${DateTime.fromISO(selectedCalendarItem.startDate).toISODate()}`;
    try {
      if (selectedCalendarItem.serviceId !== null && selectedCalendarItem.serviceId !== undefined) {
        const response = await apiInterceptor.get(url);
        if (response.status === 200) {
          setParticipantsArray(response.data);
        }
      }
    } catch (e) {

    }
  };

  const getNumberParticipants = async () => {
    const url = `/api/bookings/small-group-participants/${selectedCalendarItem.serviceId}/${DateTime.fromISO(selectedCalendarItem.startDate).toUTC()}`;
    if (selectedCalendarItem.serviceId !== null && selectedCalendarItem.serviceId !== undefined) {
      const response = await apiInterceptor.get(url);
      if (response.status === 200) {
        setNumberOfParticipans(response.data.participantsCount);
      }
    }
  };

  const [showParticipants, setShowParticipants] = useState(false);
  const { t } = useTranslation();
  const confirmationModal = useContext(ModalContext);
  const [calendarDays, setCalendarDays] = useState(3);
  // @ts-ignore
  confirmationModal.setModalType(2);
  // @ts-ignore
  confirmationModal.setMessage(t("Are you sure you want to cancel the session?"));

  useEffect(() => {
    dispatch(setWeeklyView(apiInterceptor));
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 500) {
      setCalendarDays(1);
    } else if (window.innerWidth < 768) {
      setCalendarDays(2);
    } else if (window.innerWidth < 960) {
      setCalendarDays(3);
    } else if (window.innerWidth < 1100) {
      setCalendarDays(4);
    } else if (window.innerWidth < 1268) {
      setCalendarDays(5);
    } else if (window.innerWidth < 1400) {
      setCalendarDays(6);
    } else {
      setCalendarDays(7);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);

  const handleCloseModal = () => {
    const data = {
      id: -1
    };
    dispatch(setSelectedCalendarItem(data));
  };

  return (
    <div className="weeklyView">
      <div className="calendar">
        {/* <button className={"reloadButton"} onClick={() => dispatch(setWeeklyView())}><ReloadSvg /></button>*/}

        <FullCalendar
          plugins={[interactionPlugin, timeGridPlugin]}
          events={weeklyViewData}
          eventContent={(args) => <EventItem args={args} />}
          slotMinTime={"00:00:00"}
          slotMaxTime={"24:00:00"}
          titleRangeSeparator={" - "}
          allDaySlot={false}
          locale={i18n.language}
          dayHeaderFormat={{ weekday: "long", omitCommas: false }}
          //slotDuration={"00:05:00"}
          firstDay={1}
          height={"100%"}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
            hour12: true
          }}
          views={{
            timeGridThreeDay: {
              type: "timeGrid",
              duration: { days: calendarDays },
              slotMinTime: "00:00:00",
              slotMaxTime: "24:00:00",
              weekends: true
            }
          }}
          initialView={"timeGridThreeDay"}
          titleFormat={{}}
          headerToolbar={{
            left: "",
            center: "prev,title,next today",
            right: ""
          }}
          displayEventTime={true}
        />

        <div className="selectedService">
          <div
            className={`selectedServiceCard ${
              selectedCalendarItem.serviceType === 0
                ? "selectedServiceCardVirtual"
                : "selectedServiceCardOnSite"
            }`}
            style={
              selectedCalendarItem.id === -1
                ? { display: "none" }
                : { display: "block" }
            }
          >
            <div className="text-container">
              <button className="close-button" onClick={handleCloseModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.411"
                  height="17.41"
                  viewBox="0 0 17.411 17.41"
                >
                  <g
                    id="Group_10"
                    data-name="Group 10"
                    transform="translate(1751.4 -526.99) rotate(90)"
                  >
                    <line
                      id="Line_7"
                      data-name="Line 7"
                      y2="22.622"
                      transform="translate(543.693 1734.697) rotate(45)"
                      fill="none"
                      stroke={
                        selectedCalendarItem.serviceType !== 0
                          ? "#000"
                          : "#f2f2f2"
                      }
                      stroke-width="2"
                    />
                    <line
                      id="Line_8"
                      data-name="Line 8"
                      y2="22.622"
                      transform="translate(543.693 1750.693) rotate(135)"
                      fill="none"
                      stroke={
                        selectedCalendarItem.serviceType !== 0
                          ? "#000"
                          : "#f2f2f2"
                      }
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>
              <h2>{selectedCalendarItem.name}</h2>
              <p>
                {DateTime.fromISO(selectedCalendarItem.startDate).toFormat("dd.MM.yyyy")} |{" "}
                {DateTime.fromISO(selectedCalendarItem.startDate).toFormat("HH:mm")} h|{" "}
                {selectedCalendarItem.duration}
                {"\t"}
                {t("minutes")}
              </p>
              {selectedCalendarItem.category == 0 && selectedCalendarItem.customer ? (
                <p>{selectedCalendarItem?.customer.firstName} {selectedCalendarItem?.customer.lastName}</p>) : ""}

              {selectedCalendarItem.serviceType === 2 && selectedCalendarItem.customerAddress ? (
                <div className="selectedServiceCustomerAddress">
                  <LocationSVG
                    fill="232323"
                  />
                  <p onClick={() => window.open(
                    `https://www.google.com/maps/place/${selectedCalendarItem.customerAddress.address}+${selectedCalendarItem.customerAddress.houseNumber}+${selectedCalendarItem.customerAddress.postalCode}+${selectedCalendarItem.customerAddress.city}`, "_blank")}>
                    {selectedCalendarItem.customerAddress.address}
                    {" "}
                    {selectedCalendarItem.customerAddress.houseNumber}
                    {", "}
                    {selectedCalendarItem.customerAddress.postalCode}
                    {" "}
                    {selectedCalendarItem.customerAddress.city}
                  </p>
                </div>
              ) : null}
              {selectedCalendarItem.category == 1 ? <p id={"maximumParticipants"}>
                {numberOfParticipants}/{selectedCalendarItem.maximumParticipants}{" "}
                {t("participants")}{" "}
                <DownArrowSVG
                  onClick={() => setShowParticipants(!showParticipants)}
                  className={showParticipants ? "hideDescription" : ""}
                  fill={
                    selectedCalendarItem.serviceType === 0 ? "white" : "#232323"
                  }
                />
              </p> : null}
              <p style={showParticipants ? {} : { display: "none" }}>
                {participantsArray.map((participant) => {
                  return <p>{`${participant.firstName} ${participant.lastName}`}</p>;
                })}
              </p>
              <p>
                {selectedCalendarItem.description}
              </p>
            </div>
            <div className="icon-container">
              {selectedCalendarItem.serviceType === 0 ? (
                <>
                  <VirtualSVG />
                  <p>
                    {t("virtual")}
                  </p>
                </>
              ) : null}
              {selectedCalendarItem.serviceType === 1 ? (
                <>
                  <OnSiteCoachSVG />
                  <p style={{ color: "#232323" }}>
                    {t("on site coach")}
                  </p>
                </>
              ) : null}
              {selectedCalendarItem.serviceType === 2 ? (
                <>
                  <OnSiteClientSVG />
                  <p style={{ color: "#232323" }}>
                    {t("onSiteClient")}
                  </p>
                </>
              ) : null}

              {selectedCalendarItem.category === 1 ? (
                <DificultyLevelSVG />
              ) : null}
              {difficultyLevelEnum[selectedCalendarItem.difficultyLevel]}
            </div>
            {
              selectedCalendarItem.serviceType === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    userData.virtualLink && window.open(userData.virtualLink);
                  }}
                  className="goToSession">
                  {t("launch session")}
                </button>
              )
            }
            <button onClick={(e) => {
              e.preventDefault();
              // @ts-ignore
              confirmationModal.setIsActive(true);
              // @ts-ignore
              confirmationModal.setConfirmationCallback({
                "cb":
                  selectedCalendarItem.category === 1 ? () => dispatch(cancelGroupSession(selectedCalendarItem.id,apiInterceptor)) :
                    () => dispatch(cancelBooking(selectedCalendarItem.bookingId,apiInterceptor))
              });
            }
            }>{t("cancelSession")}</button>
          </div>
        </div>
      </div>
      <div className="icon-bar">
        <div className="icon-bar-item">
          <div className="pink"></div>
          <p>
            {t("virtualSessions")}
          </p>
        </div>
        <div className="icon-bar-item">
          <div className="yellow"></div>
          <p>
            {t("onSiteSessions")}
          </p>
        </div>
        <div className="icon-bar-item biggerSVG">
          <OnSiteClientSVG />
          <p>
            {t("onSiteClient")}
          </p>
        </div>
        <div className="icon-bar-item">
          <OnSiteCoachSVG />
          <p>
            {t("on site coach")}
          </p>
        </div>
        <div className="icon-bar-item">
          <PersonalSVG />
          <p>
            {t("personalTraining")}
          </p>
        </div>
        <div className="icon-bar-item">
          <SmallGroupSVG />
          <p>
            {t("Small Group")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WeeklyView;
