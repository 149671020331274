import { useContext, useEffect, useState } from "react";
// @ts-ignore
import closeSVG from "../../../images/component_svg/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { setTrainerData } from "../../../redux/actions/registration/OnboardingAction";
import ModalContext from "../../../utility/context/modal-context";
import { currencySymbolEnum, durationInMinutes } from "../../../enums/enum";
import AuthContext from "../../../utility/context/auth-context";
import { useTranslation } from "react-i18next";
import PriceInput from "../../common/priceInput";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";

const intErrObject = {
  nameOfService: "",
  disciplines: "",
  duration: "",
  virtual: "",
  description: ""
};

const BusinessCenterServiceCreatePersonalTraining = () => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>("");
  const [errObject, setErrObject] = useState<any>(intErrObject);
  const [disciplineSelection, setDisciplineSelection] = useState<number>(0);
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  const selectDisciplinePlaceholder = t(
    "selectDiscipline"
  );
  const selectDurationPlaceholder = t(
    "selectDuration"
  );
  const descriptionPlaceholder = t(
    "descriptionPlaceholder"
  );
  const serviceNamePlaceholder = t(
    "serviceNamePlaceholder"
  );
  const minutes = t("minutes");

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.onboarding.userData);
  const personalTrainingState = useSelector(
    (state) => state.businessCenter.services.personalTraining
  );

  const editServiceSelector = useSelector(
    (state) => state.businessCenter.services.editService
  );

  const modalCtx = useContext<any>(ModalContext);
  const authCtx = useContext(AuthContext);

  const { onSiteCoach, isVirtual, onSiteClient } = userData;

  useEffect(() => {
    dispatch(setTrainerData(apiInterceptor));
  }, []);

  const handleValidateInputs = async (): Promise<boolean> => {
    return new Promise(async (resolve) => {
      let status = true;
      const errros = JSON.parse(JSON.stringify(intErrObject));
      if (!personalTrainingState.nameOfService) {
        errros.nameOfService = t("errNameOfService");
        status = false;
      }
      if (!personalTrainingState.description) {
        errros.description = t("errDescription");
        status = false;
      }
      if (personalTrainingState.disciplines?.length < 1) {
        errros.disciplines = t("errDisciplines");
        status = false;
      }
      if (personalTrainingState.duration?.length < 1) {
        errros.duration = t("errDuration");
        status = false;
      }
      setErrObject(errros);
      resolve(status);
    });
  };
  const { i18n } = useTranslation();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const isFormValid = await handleValidateInputs();
    if (!isFormValid) {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("please fill all the required fields"));
      modalCtx.setIsActive(true);
      return;
    }
    const isEditingService = editServiceSelector.id !== -1;

    let durationArray: any = [];
    let disciplinesArray: any = [];
    let serviceTypeArray: any = [];

    personalTrainingState.duration.forEach((e: any) => {
      durationArray.push(Number(e.value));
    });
    personalTrainingState.disciplines.forEach((e: any) => {
      disciplinesArray.push(e.value);
    });

    if (personalTrainingState.virtual.isChecked) {
      serviceTypeArray.push(0);
    }
    if (personalTrainingState.onSite.isCheckedCoach) {
      serviceTypeArray.push(1);
    }
    if (personalTrainingState.onSite.isCheckedClient) {
      serviceTypeArray.push(2);
    }
    const data = {
      category: 0,
      name: personalTrainingState.nameOfService,
      description: personalTrainingState.description,
      serviceTypes: serviceTypeArray,
      disciplines: disciplinesArray,
      durationInMinutes: durationArray,
      onsiteFee: personalTrainingState.onSite.price,
      virtualFee: personalTrainingState.virtual.price
    };

    apiInterceptor(`/api/services/${isEditingService ? editServiceSelector.id : "personal"}`,{
      method:isEditingService ? "PUT" : "POST",
      data: data,
    }).then(async (data) => {

      if (data.status === 201 || data.status === 200) {

        modalCtx.setModalType(0);

        modalCtx.setMessage(
          isEditingService
            ? t("privateModified")
            : t("privateCreated")
        );
        modalCtx.setIsActive(true);
        dispatch({ type: "resetServicesFields" });
      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(data.data.message);
        modalCtx.setIsActive(true);
      }
    });
  };

  const handleStateChanges = (event: any) => {
    dispatch({
      type: "updateCreateServicePersonalTraining",
      payload: { [event.target.name]: event.target.value }
    });
  };

  const handleAddDiscipline = (event: any) => {
    event.preventDefault();
    const disciplines = personalTrainingState.disciplines;
    let isDuplicated: boolean = false;

    disciplines.forEach((e: { key: string; value: string }) => {
      if (e.value.toString() === event.target.value) {
        isDuplicated = true;
      }
    });
    if (!isDuplicated) {
      if (event.target.value !== 0 && event.target.value !== "0") {
        dispatch({
          type: "editDiscipline",
          payload: [
            ...disciplines,
            { key: Date.now(), value: event.target.value }
          ]
        });
      }
    } else {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("discipline already selected"));
      modalCtx.setIsActive(true);
    }
  };
  const handleAddDuration = (event: any) => {
    const duration = personalTrainingState.duration;
    event.preventDefault();
    let isDuplicated: boolean = false;

    duration.forEach((e: { key: string; value: string }) => {
      if (e.value.toString() === event.target.value) {
        isDuplicated = true;
      }
    });
    if (!isDuplicated) {
      dispatch({
        type: "editDuration",
        payload: [{ key: Date.now(), value: event.target.value }]
      });
    } else {
      modalCtx.setModalType(0);
      modalCtx.setMessage("Duplication in durations");
      modalCtx.setIsActive(true);
    }
  };

  const handleDeleteDiscipline = (event: any, key: number) => {
    const disciplines = personalTrainingState.disciplines;
    const newArray = disciplines.filter((item: any) => item.key != key);

    dispatch({
      type: "editDiscipline",
      payload: newArray
    });
  };
  const handleDeleteDuration = (event: any, key: number) => {
    const duration = personalTrainingState.duration;
    const newArray = duration.filter((item: any) => item.key != key);
    dispatch({
      type: "editDuration",
      payload: newArray
    });
  };


  return (
    <form onSubmit={handleSubmit}>
      <div className="serviceCreationPersonalTraining">
        <div className="left">
          <div className="form-group">
            <label htmlFor="name">
              {t("nameOfService")}
            </label>
            <input
              type="text"
              id={name}
              name={"nameOfService"}
              value={personalTrainingState.nameOfService}
              placeholder={serviceNamePlaceholder}
              onChange={handleStateChanges}
              maxLength={50}
            />
            {errObject?.nameOfService &&
              !personalTrainingState.nameOfService && (
                <div className="validation-error yellow-global">
                  {errObject?.nameOfService}
                </div>
              )}
          </div>
          <div className="form-group">
            <label htmlFor="name">
              {t("disciplines")}
            </label>
            <select
              id={"disciplines"}
              name={"disciplines"}
              value={disciplineSelection}
              onChange={handleAddDiscipline}
            >
              <option value={0}>{selectDisciplinePlaceholder}</option>
              {userData.disciplines.map((v: any) => (
                <option value={v}>{v}</option>
              ))}
            </select>
            <div className="specalties_added">
              {personalTrainingState.disciplines.map((v: any) => (
                <div key={v.key} className="specaltie">
                  {v.value}
                  <span
                    key={v.key}
                    onClick={(e) => {
                      handleDeleteDiscipline(e, v.key);
                    }}
                  >
                    <img src={closeSVG} alt="deleteSVG" />
                  </span>
                </div>
              ))}
            </div>
            {errObject?.disciplines &&
              personalTrainingState.disciplines?.length < 1 && (
                <div className="validation-error yellow-global">
                  {errObject?.disciplines}
                </div>
              )}
          </div>
          <div className="form-group">
            <label htmlFor="name">
              {t("duration")}
            </label>
            <select
              id={"duration"}
              value={"duration"}
              onChange={handleAddDuration}
            >
              <option value={0}>{selectDurationPlaceholder}</option>
              {durationInMinutes.map(duration => (
                <option value={duration}> {duration} {minutes} </option>
              ))}
            </select>
            <div className="duration_added">
              {personalTrainingState.duration.map(
                (v: { key: number; value: string }) => {
                  return (
                    <div key={v.key} className="duration">
                      {v.value} {minutes}
                      <span
                        key={v.key}
                        onClick={(e) => {
                          handleDeleteDuration(e, v.key);
                        }}
                      >
                        <img src={closeSVG} alt="deleteSVG" />
                      </span>
                    </div>
                  );
                }
              )}
            </div>
            {errObject?.duration &&
              personalTrainingState.duration?.length < 1 && (
                <div className="validation-error yellow-global">
                  {errObject?.duration}
                </div>
              )}
          </div>
        </div>
        <div className="right">
          <div className="form-group">
            <label htmlFor="name">
              {t("description")}
            </label>
            <textarea
              id={"description"}
              name={"description"}
              value={personalTrainingState.description}
              placeholder={descriptionPlaceholder}
              onChange={handleStateChanges}
              maxLength={500}
            />
            {errObject?.description && !personalTrainingState.description && (
              <div className="validation-error yellow-global">
                {errObject?.description}
              </div>
            )}
          </div>

        </div>

      </div>
      <div className="serviceCreationBottom">
        <div className="serviceTypes">
          <p>
            {t("service type")}
          </p>
          <div className="service-row">
            <div className="checkbox_pink">
              <input
                type="checkbox"
                id="virtual"
                checked={personalTrainingState.virtual.isChecked}
                onChange={(e) =>
                  dispatch({
                    type: "editVirtual",
                    payload: {
                      isChecked: e.target.checked,
                      price: personalTrainingState.virtual.price
                    }
                  })
                }
                disabled={!isVirtual}
              />
              <label
                className={isVirtual ? "" : "label-disabled"}
                htmlFor="virtual"
              >
                <span className="fillElement" />
              </label>
              <p>
                {t("virtual")}
              </p>
            </div>
            <div className="bottom">

              <div className="price">
                <PriceInput
                  price={personalTrainingState.virtual.price}
                  handleOnChange={(value: string) => dispatch({
                    type: "editVirtual",
                    payload: {
                      isChecked: personalTrainingState.virtual.isChecked,
                      price: value
                    }
                  })}
                  currency={userData.currency}
                  disabled={!personalTrainingState.virtual.isChecked}
                />
              </div>

            </div>

          </div>

          <div className="service-row">
            <div className="checkbox-wraper">
              <div className="checkbox_pink">
                <input
                  type="checkbox"
                  id="onSiteCoach"
                  checked={personalTrainingState.onSite.isCheckedCoach}
                  onChange={(e) =>
                    dispatch({
                      type: "editOnSite",
                      payload: {
                        isCheckedCoach: e.target.checked,
                        price: personalTrainingState.onSite.price
                      }
                    })
                  }
                  disabled={!onSiteCoach}
                />
                <label
                  className={onSiteCoach ? "" : "label-disabled"}
                  htmlFor="onSiteCoach"
                >
                  <span className="fillElement" />
                </label>
                <p>
                  {t("on site coach")}
                </p>
              </div>
              <div className="checkbox_pink">
                <input
                  type="checkbox"
                  id="onSiteClient"
                  checked={personalTrainingState.onSite.isCheckedClient}
                  onChange={(e) =>
                    dispatch({
                      type: "editOnSite",
                      payload: {
                        isCheckedClient: e.target.checked
                      }
                    })
                  }
                  disabled={!onSiteClient}
                />
                <label
                  className={onSiteClient ? "" : "label-disabled"}
                  htmlFor="onSiteClient"
                >
                  <span className="fillElement" />
                </label>
                <p>
                  {t("onSiteClient")}
                </p>
              </div>
            </div>

            <div className="service_price">
              <div className="line_component">
                <div className="first" />
                <div className="second" />
              </div>

              <div className="bottom">

                <div className="price">
                  <PriceInput
                    price={personalTrainingState.onSite.price}
                    handleOnChange={(value: string) => dispatch({
                      type: "editOnSite",
                      payload: {
                        price: value
                      }
                    })}
                    currency={userData.currency}
                    disabled={!personalTrainingState.onSite.isCheckedCoach &&
                      !personalTrainingState.onSite.isCheckedClient}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type={"submit"}>
          {t("save")}
        </button>
      </div>

    </form>
  );
};

export default BusinessCenterServiceCreatePersonalTraining;

//[...props.specialties, {
//                 key: Date.now(),
//                 value: disciplines
//             }]
