import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeNavigation } from "../../redux/actions/layout/layoutAction";
import { useTranslation } from "react-i18next";
import i18n, { changeLanguage } from "i18next";
import { useHistory, useLocation } from "react-router-dom";
import ApiContext from "../../shared/api-interceptor/api-interceptor.component";

const ExternalStatusPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [statusMessage, setStatusMessage] = useState<string>("");
  const history = useHistory();
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  const getStatusMessage = async (statusCode: string | null) => {

    if (statusCode !== null) {
      const url = `/api/user/status-message?statusCode=${statusCode}`;
      const response = await apiInterceptor.get(url);

      if (response.status === 200) {
        setStatusMessage(response.data.message);
      } else {
      }
    }
  };
  useEffect(() => {
    if(localStorage.getItem("language")){
      changeLanguage(localStorage.getItem("language")!)
    }else{
      localStorage.setItem("language",i18n.language)
    }

    },[])
  const query = useQuery();
  const type = query.get("type");
  const status = query.get("status");
  const returnUrl = query.get("returnUrl");
  const error : number = Number(query.get("error"));


  useEffect(() => {
    dispatch(changeNavigation(-1));
    if( window.location.href.split("&").length !== 3){
      history.replace("/")
    }

    getStatusMessage(status);

   setTimeout(() => window.location.href = returnUrl!, 10000 );
  }, [status,type,returnUrl,error]);

  return (
    <div className="externalStatusPage">
      <div className="initialView">
        <div className="content">
          <div className="card">
            <div className="card_heading"><h1>{error ? t("Operation failed!") : t("The operation was successful!")}</h1>
            </div>
            <div className="cardContent">
              <p>{statusMessage}</p>
              <p >{t("You will be redirected in a few seconds")}</p>
              <p className={"redirectionText"}>{t("if you haven't been redirected in 10 seconds, please click")} <a href={"#"} onClick={() => window.location.href = returnUrl!} >{t("here")}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
export default ExternalStatusPage;
