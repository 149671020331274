import { apiURIs } from "../apiPaths";
import RequestService, { methodEnum } from "../service";
import { AxiosInstance } from "axios";

interface callInterface {
  endpoint: string;
  method: methodEnum;
  headers?: any;
  body?: any;
}
class Services {



  handleResponse = async (apiInterceptor:AxiosInstance,{ endpoint, method, body = ""}: callInterface,dispatch:any) => {


    try {
      const request = await apiInterceptor(
        endpoint,
        {
          method: method,
          data: body
        }

      );

      if (request.status === 201 || request.status === 200) {
        return {
          success: true
        }
      } else {
        return {
          success: false,
        }
      }
    } catch (error: any) {
      if (error.response) {
        return {
          success: false,
          message: error.response.data.message,
        }
      } else {
        return {
          success: false,
        }
      }
    }
  }

  async createSmallGroup(apiInterceptor:AxiosInstance,formData: any,dispatch:any) {

    return this.handleResponse(apiInterceptor,{
      endpoint: apiURIs.createSmallGroup,
      method: methodEnum.post,
      body: formData,
    },dispatch)
  }

  async updateSmallGroup(apiInterceptor:AxiosInstance,formData: any,dispatch:any) {

    return this.handleResponse(apiInterceptor,{
      endpoint: `${apiURIs.updateSmallGroup}${formData.id}`,
      method: methodEnum.put,
      body: formData
    },dispatch)
  }
}

export default new Services();
