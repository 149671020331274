import React, { useContext } from "react";
import locationSVG from "../../../images/component_svg/locationSVG.svg";
import closeIcon from "../../../images/component_svg/closeIcon.svg";
import { upcomingBookingsType } from "../../../redux/reducers/customer/customerReducer";
import { DateTime } from "luxon";
import { difficultyLevelEnum, ServiceCategoryEnum, serviceTypeEnum } from "../../../enums/enum";
import { useTranslation } from "react-i18next";
import ModalContext from "../../../utility/context/modal-context";
import {
  loadCancelledBookings,
  loadCustomerPackages,
  loadUpcomingSessions
} from "../../../redux/actions/customer/customerAction";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LocationSVG } from "../../../images/component_svg/locationSVG.svg";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";



export default function CancelSessionModal({ item, setCancleModal }: { item: upcomingBookingsType, setCancleModal: any }) {

  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;
  const modalCtx = useContext<any>(ModalContext);
  const dispatch = useDispatch();
  const publicTrainerData = useSelector(
    (state) => state.onboarding.publicTrainerData);
  const cancelSession = async () => {
    const url = `/api/bookings/${item.bookingId}/customer-cancels-booking`;

    const response = await apiInterceptor.put(url);

    if (response.status == 200) {
      modalCtx.setModalType(0);
      modalCtx.setMessage(response.data.message);
      modalCtx.setIsActive(true);
      dispatch(loadUpcomingSessions(apiInterceptor,0));
      dispatch(loadCancelledBookings(apiInterceptor,0));
      dispatch(loadCustomerPackages(apiInterceptor));



    } else {
      modalCtx.setModalType(0);
      modalCtx.setMessage(response.data.message);
      modalCtx.setIsActive(true);


    }

  }
  const { t } = useTranslation();
  return (
    <div className="cancelses-pop">
      <div className="pop-body">
        <button
          type="button"
          className="close"
          onClick={() => setCancleModal(false)}
        >
          <img src={closeIcon} alt="close" />
        </button>
        <div className="pop-cont">
          <h2>{item.service.name}</h2>
          {/* Controls if service category is Small Group, display the difficulty level of it */}
          <p>
            {t(ServiceCategoryEnum[item.serviceCategory])} {item.serviceCategory === 1 ? (" | " + t("difficulty") + ": " + t(difficultyLevelEnum[item.difficultyLevel])) : null}
          </p>
          <p>{DateTime.fromISO(item.startTime).toFormat("dd.MM.yyyy")} | {DateTime.fromISO(item.startTime).toFormat("HH:mm")} h | {item.durationInMinutes} {t("minutes")}</p>
          {item.serviceType === 1 && item.trainerAddress ? (
                <div className="selectedServiceCustomerAddress">
                  <LocationSVG
                    fill="232323"
                  />
                  <p onClick={() => item.trainerAddress && window.open(
                    `https://www.google.com/maps/place/${item.trainerAddress.address}+${item.trainerAddress.houseNumber}+${item.trainerAddress.postalCode}+${item.trainerAddress.city}`, "_blank")}>
                    {item.trainerAddress.address }
                    {" "}
                    {item.trainerAddress.houseNumber}
                    {", "}
                    {item.trainerAddress.postalCode}
                    {" "}
                    {item.trainerAddress.city}
                  </p>
                </div>
              ) : null}

          <p>
            {item.service.description}
          </p>

          <div className="pop-body-button-container">
            {item.serviceType == serviceTypeEnum.virtual ? (
            <a href={publicTrainerData.virtualLink} target="_blank">
              <button className={"btn-zoom"} type="button">
                {t("launch session")}
              </button>
            </a> ) : null}

            <button className={"cancel"} onClick={cancelSession} type="button">
              {t("cancelSession")}
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}

{/*          <div className="location">
            <img src={locationSVG} alt="locationSVG" />
            Central Park, New York
          </div>*/}
