import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeNavigation } from "../../../redux/actions/layout/layoutAction";
import { getInvoices } from "../../../redux/actions/booking/bookingAction";
import { InvoiceItemType, InvoiceType } from "../../../redux/reducers/booking/bookingReducer";
import InvoiceItem from "./InvoiceItem";
import { useTranslation } from "react-i18next";

// @ts-ignore
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
import i18n from "i18next";
import { useHistory } from "react-router-dom";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";
const Invoices = () => {

  const dispatch = useDispatch()
  const userData = useSelector(state => state.onboarding.userData)
  const trainerInvoices = useSelector(state => state.booking.trainerInvoices)
const history = useHistory();
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;

  const [tab,setTab] = useState(0);
  const [dateFrom,setDateFrom] = useState(DateTime.now().minus({day: 14}).set({hour:0,minute:0,second:0}));
  const [dateTo,setDateTo] = useState(DateTime.now().set({hour:0,minute:0,second:0}));
  const { t } = useTranslation();


  useEffect(() => {
    dispatch(changeNavigation(1))
    dispatch(getInvoices(apiInterceptor,dateFrom,dateTo))
  },[dateFrom,dateTo,history.location.pathname,userData])

  return (
    <div className="invoices">
          <div className="invoicesHeader">
            <h1>{t("invoices")}</h1>
            <hr />
          </div>
          <div className="card">
            <div className="invoiceTabs">
              <div className={` ${tab === 0 ? "active" : ""}`}
                   onClick={() => setTab(0)}
              >
                <p>{t("invoicesClients")}</p>
              </div>
              <div className={` ${tab === 1 ? "active" : ""}`}
                   onClick={() => setTab(1)}>
                <p>{t("invoicesSkulp")}</p>
              </div>
            </div>
            <div className="date-range-picker">
              <DatePicker
                id={"dateFrom"}

                onChange={(date) => {
                  if(date !== null){
                    setDateFrom(DateTime.fromJSDate(date))
                  }
                }}
                selected={dateFrom.toJSDate()}
                dateFormat="dd.MM.yyyy"
                locale={i18n.language === "de" ? de : ""}
                calendarStartDay={1}
              />
              <span> - </span>
              <DatePicker
                id={"dateTo"}

                onChange={(date) => {
                  if(date !== null){
                    setDateTo(DateTime.fromJSDate(date))
                  }
                }}
                selected={dateTo.toJSDate()}
                dateFormat="dd.MM.yyyy"
                locale={i18n.language === "de" ? de : ""}
                calendarStartDay={1}
              />
            </div>



            <div className="invoicesTable">
              {  (tab === 0 ?trainerInvoices.customerInvoices || [] : trainerInvoices.skulpInvoices || [] ).map((item: InvoiceItemType) => {
               return <InvoiceItem key={item.invoiceNumber}  item={item} />
              })}
            </div>
          </div>
    </div>
  );
};

export default Invoices;
