import i18n from "i18next";
import { PlatformType } from "../../enums/enum";
import axios, { AxiosAdapter, AxiosError, AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { AxiosInstance } from "axios";
import { useHistory } from "react-router-dom";
import AuthContext from "../../utility/context/auth-context";
import ModalContext from "../../utility/context/modal-context";
import { setIsLoading } from "../../redux/actions/layout/layoutAction";
import { useDispatch } from "react-redux";
import { unstable_createMuiStrictModeTheme } from "@mui/material";
import { getPublicTrainerData } from "../../redux/actions/registration/OnboardingAction";
import authContext from "../../utility/context/auth-context";
import modalContext from "../../utility/context/modal-context";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BE_BASEURL,
  withCredentials: true,
  //validateStatus:(status) =>  status >= 200 && status < 400
});
instance.defaults.adapter = require("axios/lib/adapters/http");


let lastRequest: AxiosRequestConfig;

const ApiContext = createContext({
  apiInterceptor: instance
});


export const ApiContextProvider = (props: any) => {

  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  let isRefreshing: boolean = false;
  let isLogout: boolean =false;


  const contextValue = {
    apiInterceptor: instance
  };



  instance.interceptors.request.use((config: AxiosRequestConfig) => {
    lastRequest = config;
    const urlSplit = config.url?.split("/");
    let isUndefined: boolean = false;
    urlSplit?.forEach(e => isUndefined = (e === "undefined"));
    if (isUndefined) {
      return null;
    }
    if(isRefreshing){
      Promise.reject("Is Refreshing");
    }

    config.headers = {
      "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
      "Content-Type": "application/json",
      "Accept": "text/plain",
      "PlatformType": PlatformType.Web,
      ...config.headers
    };
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = ` bearer ${token}`;
    }

    dispatch(setIsLoading(true));

    return config;
  }, (e) => {
    return Promise.reject(e);
  });


  instance.interceptors.response.use(async (response) => {

    setTimeout(() => dispatch(setIsLoading(false)), 200);
    console.log(response.config.url, response.status);
    return response;
  }, async (err) => {
    const originalRequest = err.config;

    setTimeout(() => dispatch(setIsLoading(false)), 200);

    if( err.response.status === 400 || err.response.status === 404){
      return err.response
    }



    if (originalRequest.url !== "/api/User/token" && err.response) {
      console.log(isRefreshing);


      if (err.response.status === 401 && !originalRequest._retry && !isRefreshing &&  !isLogout) {
        isRefreshing = true;


        originalRequest._retry = false;
        try {
          const res = await instance("/api/User/refresh-token", {
            method: "post",
            baseURL: process.env.REACT_APP_BE_BASEURL,
            withCredentials: true,
            xsrfCookieName: "refreshToken"

          }).then((res) => {

            if(res.status === 400){
              localStorage.clear();
              return Promise.reject();
            }else{
            localStorage.setItem("token", res.data.token);
            console.log(res.config.url, res.status);
            authCtx.setToken(res.data.token);
            }

          }).finally(() => {
            isRefreshing = false;
            dispatch(setIsLoading(false))
            window.location.reload();
          });



          return instance(originalRequest);



        } catch (_error) {
          if(originalRequest.url === "/api/User/logout"){
            isLogout = true;
            console.log("isLogout",isLogout);
          }
          if (authCtx.isLoggedIn)
            authCtx.logout();
          dispatch(setIsLoading(false));
          return Promise.reject(_error);
        }
      }
    }

  });


  return (
    <ApiContext.Provider value={contextValue}>
      {props.children}
    </ApiContext.Provider>
  );

};

export default ApiContext;
