import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect, RouteProps, useHistory } from "react-router-dom";
import AuthContext from "../../utility/context/auth-context";
import ModalContext from "../../utility/context/modal-context";
import { useDispatch, useSelector } from "react-redux";
import ApiContext from "../api-interceptor/api-interceptor.component";
import { User } from "../service-proxies/service-proxies";

interface PrivateRouteProps extends RouteProps {
  role: "customer" | "admin" | "trainer";
  isOnboarded?: boolean;
}

interface UserData {
  email: string,
  refreshTokenExpiration: string,
  role: string,
  trainerId: string,
  userId: string,
  userName: string,
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
                                                     component: Component,
                                                     role,
                                                     isOnboarded,
                                                     ...rest
                                                   }) => {


  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const onboardingStatus = useSelector(state => state.onboarding.userData.onboardingStatus);
  const dispatch = useDispatch();
  const history =  useHistory();



/*  useEffect(() => {
    if (!isAuthenticated) {
      modalCtx.setMessage("Deine Sitzung ist abgelaufen, bitte logge dich erneut ein.");
      modalCtx.setModalType(0);
      modalCtx.setIsActive(true);
    }
  }, [isAuthenticated]);*/

  return (<Route
    {...rest}
    render={(props) => {
      if (!authCtx.isLoggedIn) {
        //return <Redirect to="/" />;
      }

      if (role === "admin" && authCtx.role === "Admin") {
        return Component ? <Component {...props} /> : null;
      }
      if (role === "trainer" && authCtx.role === "Trainer") {
        if (!isOnboarded) {
          return Component ? <Component {...props} /> : null;
        }
        if (isOnboarded && onboardingStatus === 5) {
          return Component ? <Component {...props} /> : null;
        } else {
          return <Redirect to="/" />;
        }
      }
      if (role === "customer" && authCtx.role === "User") {
        return Component ? <Component {...props} /> : null;
      }

      return <Redirect to="/" />;
    } }
  />);
};

export default PrivateRoute;
