import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SignUpFormsAction } from "../../../redux/actions/registration/SignUpFormsAction";
import { countrysEnum, emailRegex } from "../../../enums/enum";
import { useTranslation } from "react-i18next";
import ModalContext from "../../../utility/context/modal-context";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";

const ResetPassword = () => {
  const [emailErr, setEmailErr] = useState<string>("");
  const userdata = useSelector((state) => state.registration);
  const { i18n , t} = useTranslation();

  const enterEmailPlaceholder = t("enter email");
  const dispatch = useDispatch();
  const modalCtx = useContext<any>(ModalContext);
  const apiContext = useContext(ApiContext);
  const {apiInterceptor} = apiContext;

  const handleSubmit = async () => {
    const { email } = userdata;
    //TODO: implement Restet Password Endpoint And Redirection to resendPasswordInstructions
    if (!email) {
      setEmailErr(t("email is required"));
      return;
    }
    if (!email.match(emailRegex)) {
      setEmailErr(t( "errEmailInvalid"));
      return;
    }
    setEmailErr("");
    const url = `/api/User/forgotPassword`;

    const response = await apiInterceptor.post(url, {email:email});
    const { message } = await response.data;

    if (response.status === 200) {
      dispatch(SignUpFormsAction({ registrationStep: "resendInstructions" }));
    } else {
      modalCtx.setModalType(0);
      modalCtx.setMessage(message);
      modalCtx.setIsActive(true);
    }

    dispatch({
      type: "handleRegistrationForm",
      payload: {
        registrationStep: "resendInstructions",
      },
    });
  };

  return (
    <div className="resetPassword">
      <h1>
        {t("Password reset")}
      </h1>
      <div className="form-group">
        <label htmlFor="password">
          {t("enter email")}{" "}
        </label>
        <input
          type="email"
          id={"password"}
          required={true}
          value={userdata.email}
          placeholder={enterEmailPlaceholder}
          onChange={(e) =>
            dispatch({
              type: "setEmail",
              payload: e.target.value,
            })
          }
        />
        {emailErr ? <div className="error">{emailErr}</div> : null}
      </div>

      <button onClick={handleSubmit}>
        {t("send instructions to this mail")}
      </button>
    </div>
  );
};

export default ResetPassword;
