import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getPublicTrainerData,
    setOnboardingStatusAction,
    setTrainerData
} from "../../../redux/actions/registration/OnboardingAction";
import { changeNavigation } from "../../../redux/actions/layout/layoutAction";
import PersonalUrl from "./PersonalUrl";
import PersonalSpace from "./PersonalSpace";
import AuthContext from "../../../utility/context/auth-context";
import TrainerOnboardingBottomBar from "../../../components/onboarding/TrainerOnboarding/TrainerOnboardingBottomBar";
import Spinner from "../../../components/layout/Spinner";
import imagePlaceholder from "../../../images";

import { Link, useHistory } from "react-router-dom";
import { setPersonalSpacePreviewData } from "../../../redux/actions/personalSpacePreview/personalSpacePreviewAction";
import ModalContext from "../../../utility/context/modal-context";
import { imageUploadType } from "../../../enums/enum";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import ApiContext from "../../../shared/api-interceptor/api-interceptor.component";

const errorsObject = {
    personalUrl: "",
    nickName: "",
    name: "",
    slogan: ""
};

const TrainerOnboardingEditSpace = () => {
    const apiContext = useContext(ApiContext);
    const {apiInterceptor} = apiContext;
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.onboarding.userData);
    const modalCtx = useContext<any>(ModalContext);
    const history = useHistory();
    const { i18n, t } = useTranslation();

    const [personalUrlCookie, setPersonalUrlCookie, removePersonalUrlCookie] = useCookies(["PersonalUrlCookie"]);
    const [nickNameCookie, setNickNameCookie, removeNickNameCookie] = useCookies(["NickNameCookie"]);
    const [nameCookie, setNameCookie, removeNameCookie] = useCookies(["NameCookie"]);
    const [sloganCookie, setSloganCookie, removeSloganCookie] = useCookies(["SloganCookie"]);

    const [personalSpaceImage, setPersonalSpaceImage] = useState<string>(
        imagePlaceholder.personalSpace
    );

    const [errObject, setErrObject] = useState<any>(errorsObject);
    const [personalUrl, setPersonalUrl] = useState<string>(
        userData.trainerSpace?.spaceUrl !== null
            ? userData.trainerSpace?.spaceUrl
            : ""
    );
    const [nickName, setNickName] = useState<string>(
        userData.trainerSpace?.nickname !== null
            ? userData.trainerSpace?.nickname
            : ""
    );
    const [name, setName] = useState<string>(
        userData.trainerSpace?.name !== null ? nameCookie.NameCookie || userData.trainerSpace?.name : ""
    );
    const [slogan, setSlogan] = useState<string>(
        userData.trainerSpace?.slogan !== null ? userData.trainerSpace?.slogan : ""
    );
    const [showSpinner, setShowSpinner] = useState(false);
    // TODO: Storing information in one single cookie

    const removeCookies = () => {
        removePersonalUrlCookie("PersonalUrlCookie", { path: "/" });
        removeNickNameCookie("NickNameCookie", { path: "/" });
        removeNameCookie("NameCookie", { path: "/" });
        removeSloganCookie("SloganCookie", { path: "/" });
    };

    useEffect(() => {
        if (personalUrlCookie.PersonalUrlCookie != null) {
            setPersonalUrl(personalUrlCookie.PersonalUrlCookie);
        }
        if (nickNameCookie.NickNameCookie != null) {
            setNickName(nickNameCookie.NickNameCookie);
        }
        if (nameCookie.NameCookie != null) {
            setName(nameCookie.NameCookie);
        }
        if (sloganCookie.SloganCookie != null) {
            setSlogan(sloganCookie.SloganCookie);
        }
    }, [userData]);

    useEffect(() => {
        dispatch(setTrainerData(apiInterceptor));
        dispatch(getPublicTrainerData(apiInterceptor,history?.location?.pathname.split("/")[2]));
        dispatch(setOnboardingStatusAction(3));
        dispatch(changeNavigation(0));

        if (
            userData.trainerSpace?.logoUrl !== "" &&
            userData.trainerSpace !== null
        ) {
            setPersonalSpaceImage(
                `${process.env.REACT_APP_BE_BASEURL}/api/files/${userData.trainerSpace?.logoUrl}/download`
            );
            dispatch(
                setPersonalSpacePreviewData({
                    logoUrl: `${process.env.REACT_APP_BE_BASEURL}/api/files/${userData.trainerSpace?.logoUrl}/download`
                })
            );
        } else {
            setPersonalSpaceImage(imagePlaceholder.personalSpace);
            dispatch(
                setPersonalSpacePreviewData({ logoUrl: imagePlaceholder.personalSpace })
            );
        }
    }, [userData.trainerSpace?.logoUrl]);

    useEffect(() => {
        if (userData.trainerSpace?.spaceUrl !== null && userData.trainerSpace?.spaceUrl !== "" && !personalUrlCookie.PersonalUrlCookie) {
            setPersonalUrl(userData.trainerSpace?.spaceUrl);
            dispatch(setPersonalSpacePreviewData({ spaceUrl: userData.trainerSpace?.spaceUrl }));
        }
        if (userData.trainerSpace?.name !== null && userData.trainerSpace?.name !== "" && !nameCookie.NameCookie) {
            setName(userData.trainerSpace?.name);
            dispatch(setPersonalSpacePreviewData({ name: userData.trainerSpace?.name }));
        }
        if (userData.trainerSpace?.nickname !== null && userData.trainerSpace?.nickname !== "" && !nickNameCookie.NickNameCookie) {
            setNickName(userData.trainerSpace?.nickname);
            dispatch(setPersonalSpacePreviewData({ nickname: userData.trainerSpace?.nickname }));
        }
        if (userData.trainerSpace?.slogan !== null && userData.trainerSpace?.slogan !== "" && !sloganCookie.SloganCookie) {
            setSlogan(userData.trainerSpace?.slogan);
            dispatch(setPersonalSpacePreviewData({ slogan: userData.trainerSpace?.slogan }));
        }
        //COOKIE
        if (!!personalUrlCookie.PersonalUrlCookie) {
            setPersonalUrl(personalUrlCookie.PersonalUrlCookie);
            dispatch(setPersonalSpacePreviewData({ spaceUrl: personalUrlCookie.PersonalUrlCookie }));
        }
        if (!!nameCookie.NameCookie) {
            setName(nameCookie.NameCookie);
            dispatch(setPersonalSpacePreviewData({ name: nameCookie.NameCookie }));
        }
        if (!!nickNameCookie.NickNameCookie) {
            setNickName(nickNameCookie.NickNameCookie);
            dispatch(setPersonalSpacePreviewData({ nickname: nickNameCookie.NickNameCookie }));
        }
        if (!!sloganCookie.SloganCookie) {
            setSlogan(sloganCookie.SloganCookie);
            dispatch(setPersonalSpacePreviewData({ slogan: sloganCookie.SloganCookie }));
        }
    }, [userData]);

    const handleNickName = (e: any) => {
        setNickName(e.target.value);
        setNickNameCookie("NickNameCookie", e.target.value, { path: "/" });
        dispatch(setPersonalSpacePreviewData({ nickname: e.target.value }));
    };
    const handleName = (e: any) => {
        setName(e.target.value);
        setNameCookie("NameCookie", e.target.value, { path: "/" });
        dispatch(setPersonalSpacePreviewData({ name: e.target.value }));
    };
    const handleSlogan = (e: any) => {
        setSlogan(e.target.value);
        setSloganCookie("SloganCookie", e.target.value, { path: "/" });
        dispatch(setPersonalSpacePreviewData({ slogan: e.target.value }));
    };
    const handleSpaceUrl = (e: any) => {
        setPersonalUrl(e.target.value);
        setPersonalUrlCookie("PersonalUrlCookie", e.target.value, { path: "/" });
        dispatch(setPersonalSpacePreviewData({ spaceUrl: e.target.value }));
    };

    const authCtx = useContext(AuthContext);

    //Upload image file
    const uploadImageFile = async (e: any) => {

        let data = new FormData();
        data.append("file", e.target.files[0]);

        const url = `/api/trainers/upload?type=${imageUploadType.TrainerSpace}`;

        const response = await apiInterceptor.put(url, data);


        if (response.status === 200) {
            setPersonalSpaceImage(
                `${process.env.REACT_APP_BE_BASEURL}/api/files/${response.data.id}/download`
            );
            dispatch(setTrainerData(apiInterceptor));
        } else {
            modalCtx.setModalType(0);
            modalCtx.setMessage(response.data.message);
            modalCtx.setIsActive(true);
        }
    };

    const handleValidateInputs = async (): Promise<boolean> => {
        return new Promise(async (resolve) => {
            const errros = JSON.parse(JSON.stringify(errorsObject));
            let valid = true;
            if (!personalUrl) {
                errros.personalUrl = t("errPersonalURL");
                valid = false;
            } else {
                const urlRegx = /^(?=.*[a-z])[a-z-\d]+$/i;
                if (
                    urlRegx.test(personalUrl) === false ||
                    personalUrl.split(" ").length > 1
                ) {
                    errros.personalUrl = t(
                        "URL can contain only letters, numbers and dash characters."
                    );
                    valid = false;
                }
            }
            if (!nickName) {
                errros.nickName = t("errNickName");
                valid = false;
            }
            if (!name) {
                errros.name = t("errName");
                valid = false;
            }
            if (!slogan) {
                errros.slogan = t("errSlogan");
                valid = false;
            }
            setErrObject(errros);
            resolve(valid);
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const isFormValid = await handleValidateInputs();
        if (!isFormValid) {
            modalCtx.setModalType(0);
            modalCtx.setMessage(t("please fill all the required fields"));
            modalCtx.setIsActive(true);
            return;
        }

        const updatedData = {
            ...userData,
            onboardingStatus: 4,
            trainerSpace: {
                ...userData.trainerSpace,
                spaceUrl: personalUrl,
                slogan: slogan,
                nickname: nickName,
                name: name
            }
        };


        await apiInterceptor.put(
            `/api/trainers/user`,updatedData)
            .then(async (data) => {
                if (data.status === 200) {
                    dispatch(setTrainerData(apiInterceptor));
                    removeCookies();
                    history.replace("/registration-process-payment");
                } else {
                    modalCtx.setModalType(0);
                    modalCtx.setMessage(data.data.message);
                    modalCtx.setIsActive(true);
                }
            })
            .catch((e) => console.log("catch", e.message));
    };

    const handlePreviousStep = () => {
        history.replace("/registration-process-business-settings");
    };

    // useEffect(() => {
    //   setPersonalUrl(userData.trainerSpace?.spaceUrl !== null
    //     ? userData.trainerSpace?.spaceUrl
    //     : "")
    //   setNickName(userData.trainerSpace?.nickname !== null
    //     ? userData.trainerSpace?.nickname
    //     : "")
    //   setName(userData.trainerSpace?.name !== null ? userData.trainerSpace?.name : "")
    //   setSlogan(userData.trainerSpace?.slogan !== null ? userData.trainerSpace?.slogan : "")
    // },[userData.id])

    return (
        <div className={"initialView"}>
            <div className="content">
                <div className="pageTitle">
                    <h1>
                        {t("editYourSpace")}
                    </h1>
                    <hr />
                </div>
                {showSpinner && <Spinner />}
                <form onSubmit={handleSubmit} className={"editSpaceForm"}>
                    <Link
                        to={"/trainer-personal-site-preview"}
                        className={"previewButton"}
                    >
                        {t("previewSite")}
                    </Link>
                    <PersonalUrl
                        personalUrl={personalUrl}
                        setPersonalUrl={setPersonalUrl}
                        handleSpaceUrl={handleSpaceUrl}
                        errObject={errObject}
                    />
                    <PersonalSpace
                        personalSpaceImage={personalSpaceImage}
                        setPersonalSpaceImage={setPersonalSpaceImage}
                        uploadImageFile={uploadImageFile}
                        slogan={slogan}
                        setSlogan={setSlogan}
                        name={name}
                        setName={setName}
                        nickName={nickName}
                        setNickName={setNickName}
                        showSpinner={showSpinner}
                        handleSlogan={handleSlogan}
                        handleName={handleName}
                        handleNickName={handleNickName}
                        errObject={errObject}
                    />
                    <TrainerOnboardingBottomBar handlePreviousStep={handlePreviousStep} />
                </form>
            </div>
        </div>
    );
};

export default TrainerOnboardingEditSpace;
